import React from "react";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <>
      <section className="breadscrumb-section pt-0">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="breadscrumb-contain">
                <h2>About Us</h2>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="fa-solid fa-house" />
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      About Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fresh-vegetable-section section-lg-space">
        <div className="container-fluid-lg">
          <div className="row gx-xl-5 gy-xl-0 g-3 ratio_148_1">
            <div className="col-xl-6 col-12 p-4">
              <div className="row g-sm-4  g-2">
                <div className="col-12">
                  <div className="fresh-image-2">
                    <div>
                      <img
                        src="/assets/images/contract-gallery-3-big (1).png"
                        className="bg-img blur-up lazyload"
                        alt
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="fresh-image">
                    <div>
                      <img
                        src="/assets/images/aboutcart1.jpeg"
                        className="bg-img blur-up lazyload"
                        alt=""
                        width={700}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="fresh-image">
                    <div>
                      <img
                        src="/assets/images/chemistry-scientist-analyzing-liquid-mixture-herbal-extract-perfumes-stock-photo-131848232_1.jpg"
                        className="bg-img blur-up lazyload"
                        alt=""
                        width={700}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-12 p-2">
              <div className="fresh-contain p-center-left">
                <div>
                  <div className="review-title">
                    <h4>About Us</h4>
                    <h2>Welcome to Bekhup: </h2>
                    <h3>Empowering Wellness Naturally</h3>
                  </div>
                  <div className="delivery-list">
                    <p
                      className="text-content"
                      style={{ textAlign: "justify" }}
                    >
                      At Bekhup <b>(Branch of Moon Spagyric Pharma)</b>, we are more than just an e-commerce platform; we are a community-driven initiative with a vision to revolutionize the way we approach wellness. Founded on the principles of harnessing the wisdom of both traditional herbal remedies and modern medicine, Bekhup aims to serve society with peer knowledge and expertise.
                    </p>
                    <h3>Our Mission</h3>
                    <p
                      className="text-content"
                      style={{ textAlign: "justify" }}
                    >
Our mission is simple yet profound: to empower individuals across the globe with nature-inspired products that cater to modern health concerns. We believe in the power of ancient natural remedies and strive to bring them back to the forefront of wellness practices. From skincare to herbal medicines for chronic ailments, we are committed to offering products that prioritize both efficacy and sustainability.
                    </p>
                    <h3>What Sets Us Apart</h3>
                    <p
                      className="text-content"
                      style={{ textAlign: "justify" }}
                    >
Bekhup specializes in natural skincare, haircare, beauty, men's care, and baby care products. Our range includes everything from organic and vegan formulations to herbal remedies for a variety of health conditions. Each product is carefully crafted to ensure the highest quality, utilizing the expertise of our manufacturing facility based in Telangana, India.
                    </p>
                    <h3>Our Commitment to You</h3>
                    <p
                      className="text-content"
                      style={{ textAlign: "justify" }}
                    >
We are proud to be a 100% India-based business, rooted in the rich tradition of Indian manufacturing excellence. Through our platform, we not only showcase our own products but also provide a space for small-scale manufacturers, startup companies, and traders to advertise and sell their offerings. By supporting local businesses and promoting sustainable practices, we aim to create a positive impact on both our community and the environment.
                    </p>
                    <h3>Join Us on this Journey</h3>
                    <p
                      className="text-content"
                      style={{ textAlign: "justify" }}
                    >
Whether you're looking for natural skincare solutions, herbal remedies, or simply want to support ethical and sustainable practices, Bekhup welcomes you with open arms. Join us as we embark on a journey towards holistic wellness and empowerment. Together, let's redefine the way we care for ourselves and our planet.                    </p>

                    {/* <p className="text-content">
                      <b>
                        GST registration number of bekhup.com (
                        retails) is 20DSYPM2888F1Z7.
                      </b>
                    </p> */}
                    <ul className="delivery-box">
                      <li>
                        <div className="delivery-box">
                          <div className="delivery-icon">
                            <img
                              src="https://themes.pixelstrap.com/fastkart/assets/svg/3/delivery.svg"
                              className="blur-up lazyload"
                              alt
                            />
                          </div>
                          <div className="delivery-detail">
                            <h5 className="text">
                              Free Delivery For Order Over ₹500
                            </h5>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="delivery-box">
                          <div className="delivery-icon">
                            <img
                              src="https://themes.pixelstrap.com/fastkart/assets/svg/3/leaf.svg"
                              className="blur-up lazyload"
                              alt
                            />
                          </div>
                          <div className="delivery-detail">
                            <h5 className="text">Genuine Products</h5>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="delivery-box">
                          <div className="delivery-icon">
                            <img
                              src="https://themes.pixelstrap.com/fastkart/assets/svg/discount.svg"
                              className="blur-up lazyload"
                              alt
                            />
                          </div>
                          <div className="delivery-detail">
                            <h5 className="text">Daily Mega Discounts</h5>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="delivery-box">
                          <div className="delivery-icon">
                            <img
                              src="https://themes.pixelstrap.com/fastkart/assets/svg/market.svg"
                              className="blur-up lazyload"
                              alt
                            />
                          </div>
                          <div className="delivery-detail">
                            <h5 className="text">Best Price On The Market</h5>
                          </div>
                        </div>
                      </li>
                    </ul>
                        <h2 style={{textAlign:'center',marginTop:'10px'}}>Thank you for choosing Bekhup.</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Section Start */}
      {/* <footer className="section-t-space ">
        <div className="container-fluid-lg">
          <div className="service-section">
            <div className="row g-3">
              <div className="col-12">
                <div className="service-contain">
                  <div className="service-box">
                    <div className="service-image">
                      <img
                        src="https://themes.pixelstrap.com/fastkart/assets/svg/product.svg"
                        className="blur-up lazyload"
                        alt
                      />
                    </div>
                    <div className="service-detail">
                      <h5>Every Fresh Products</h5>
                    </div>
                  </div>
                  <div className="service-box">
                    <div className="service-image">
                      <img
                        src="https://themes.pixelstrap.com/fastkart/assets/svg/delivery.svg"
                        className="blur-up lazyload"
                        alt
                      />
                    </div>
                    <div className="service-detail">
                      <h5>Free Delivery For Order Over ₹500</h5>
                    </div>
                  </div>
                  <div className="service-box">
                    <div className="service-image">
                      <img
                        src="https://themes.pixelstrap.com/fastkart/assets/svg/discount.svg"
                        className="blur-up lazyload"
                        alt
                      />
                    </div>
                    <div className="service-detail">
                      <h5>Daily Mega Discounts</h5>
                    </div>
                  </div>
                  <div className="service-box">
                    <div className="service-image">
                      <img
                        src="https://themes.pixelstrap.com/fastkart/assets/svg/market.svg"
                        className="blur-up lazyload"
                        alt
                      />
                    </div>
                    <div className="service-detail">
                      <h5>Best Price On The Market</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
      {/* Footer Section End */}

      {/* Map Section Start */}
      <section className="map-section">
        <div className="container-fluid p-0">
          <div className="map-box">
            {/* <iframe style="height:100%;width:100%;border:0;" frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=8-2-603/B/34/39/1,++Singada+Kunta,++Zehra+Nagar,++Road+No:10,++Banjara+Hills,++Hyderabad,++Telangana+State,Pincode:+500034&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe> */}
            <iframe
              src="https://www.google.com/maps/embed/v1/place?q=8-2-603/B/34/39/1,++Zehra+Nagar,++Road+No:10,++Banjara+Hills,++Hyderabad,++Telangana+State,Pincode:+500034&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
          {/* <div style="max-width:100%;list-style:none; transition: none;overflow:hidden;width:1280px;height:397px;"><div id="google-maps-display" style="height:100%; width:100%;max-width:100%;"><iframe style="height:100%;width:100%;border:0;" frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=8-2-603/B/34/39/1,++Singada+Kunta,++Zehra+Nagar,++Road+No:10,++Banjara+Hills,++Hyderabad,++Telangana+State,Pincode:+500034&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe></div><a class="embed-ded-maphtml" href="https://www.bootstrapskins.com/themes" id="grab-maps-authorization">premium bootstrap themes</a><style>#google-maps-display img{max - height:none;max-width:none!important;background:none!important;}</style></div> */}
        </div>
      </section>
      {/* Map Section End */}
    </>
  );
};

export default AboutUs;
