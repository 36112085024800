export const Data=[
    {
        id:1,
        Que:"What are the uses of Ubtan?",
        Ans:`India is the land of spices, and we have crafted various skincare 
        essentials using simple yet effective ingredients from your kitchen. Ubtan is a 
        traditional face mask made with natural and skin-nourishing ingredients like Turmeric,
         Saffron, Gram Flour, and many more. This natural face mask can be modified with
          ingredients per your comfort and skincare needs. It has been used for centuries
           by women to add a healthy and radiant glow to the face. Ubtan addresses various
            uses for skincare. It is an effective face cleanser for men and women that helps
             make your skin feel healthy and refreshed from within. It helps enhance your skin’s
              health naturally and improves your skin’s complexion. It can also be a nourishing 
              face scrub as it also enables easy tan removal and helps remove excessive
               facial hair with gentle care and added nourishment.It keeps your skin bright
                without making it excessively oily or dry in any way.`
    },
    {
        id:2,
        Que:"How is Ubtan made?",
        Ans:`Ubtan is a traditional Indian skincare recipe made
         with natural and skin-nourishing ingredients from your kitchen, 
         like Gram Flour, Turmeric, Honey, and Milk. Optionally, ingredients
          like Sandalwood, Rose Water, and Yogurt can also be added for
           glowing skin. These ingredients are mixed together in a considered
            quantity as per your requirements and blended into a smooth paste
             for even application. Ubtan has exfoliating and skin-brightening 
             properties to rejuvenate your skin. Indians have been using homemade
              Ubtan for eons now. Mainly, it is applied before special occasions,
               such as weddings, to restore the skin’s natural glow. Ubtan also
                helps to reduce sun tan and reverse signs of aging. It has natural
                 antioxidants to help fight free radicals in the skin for a youthful
                  glow. You may add different ingredients to 
                make your Ubtan pack depending on your unique skin care goals.`
    },
    {
        id:3,
        Que:"What are the uses of Ubtan?",
        Ans:`India is the land of spices, and we have crafted various
         skincare essentials using simple yet effective ingredients 
         from your kitchen. Ubtan is a traditional face mask made with 
         natural and skin-nourishing ingredients like Turmeric, Saffron,
          Gram Flour, and many more. This natural face mask can be modified
           with ingredients per your comfort and skincare needs. It has been
            used for centuries by women to add a healthy and radiant glow to the
             face. Ubtan addresses various uses for skincare. It is an effective 
             face cleanser for men and women that helps make your skin feel healthy
              and refreshed from within. It helps enhance your skin’s health naturally 
              and improves your skin’s complexion. It can also be a nourishing face scrub
               as it also enables easy tan removal and helps remove excessive facial hair 
               with gentle care and added nourishment. It keeps your
         skin bright without making it excessively oily or dry in any way.`
    },
    {
        id:4,
        Que:"How to apply Ubtan on your face?",
        Ans:`Step 1: Make a paste: First, make a thick paste with 
        natural ingredients like Gram Flour, Turmeric, Milk, and Saffron.
         You may add a pinch of saffron, rose milk, and yogurt for added
          benefits. Mix these substances and blend to make a smooth paste, 
          ensuring a thick consistency. Step 2: Apply evenly: Wash your face
           with a sulfate-free face cleanser. Applying Ubtan on a clean facial
            canvas is important for better penetration of the ingredients. Then, 
            apply a small amount of the Ubtan paste evenly on your face and neck,
             avoiding the eye area. Step 3: Leave it on: Leave it for 10 minutes or
              until fully absorbed. As it dries, the paste feels dry on the skin.
               You may use water to moisten the skin a little and use only gentle
                circular motions, scrubbing off the paste. This will also help shed
                 dead skin cells. Step 4: Rinse thoroughly: Use normal tap water to rinse
                  off. Pat dry your face. The result is refreshed and rejuvenated skin. 
                  Apply a moisturizer to lock in moisture and get a healthy glow. You may 
                  use the Ubtan pack once or
         twice weekly for younger, brighter-looking skin.`
    },
    {
        id:5,
        Que:"Is Ubtan safe for the skin?",
        Ans:`Yes, Ubtan is safe for the skin. Ubtan is crafted
         with naturally nourishing ingredients that offer nourishment
          to your skin. It is traditionally prepared, which makes it safe
           and effective for all skin types, including sensitive skin. 
           Toxin-free formulations made with natural ingredients have no 
           potential side effects. Ubtan offers a long-lasting and 
           effortless glow to your face and helps reduce age spots and 
           blemishes with gentle care. However, we still recommend doing a
            patch before incorporating it in 
        your daily skincare to rule out chances of minor issues.`
    },
    {
        id:6,
        Que:"What are the benefits of Ubtan?",
        Ans:`Ubtan has amazing skincare benefits that are not known by many. 
        It helps in easy tan removal without compromising your skin’s natural
         health. It also considers how delicate your skin is and offers adequate 
         hydration and moisture to it. Lastly, it adds an effortless radiance to 
         your skin and makes it feel healthy inside out. These long-lasting benefits
          make it an amazing skincare specialist. An Ubtan Face Pack is made with 
          natural and skin-benefiting ingredients that add a new chapter of nourishment
           to your daily skincare routine. It is a face mask that can be used thrice a 
           week on your facial skin to cleanse and hydrate your skin gently. It is also 
           a nourishing scrub that helps remove dead skin cells from your skin and gives
            you a revived look. Using it 
        consistently can make your skin look flawless and naturally healthy.`
    },
    {
        id:7,
        Que:"How to use Bekhup Ubtan Face Wash?",
        Ans:`Bekhup Ubtan Face Wash is a natural face cleanser with the 
        goodness of skin-benefiting ingredients like Turmeric, Walnut Beads,
         Saffron, and Carrot Seed Oil. The ingredients work together to promote tan
          removal. It also helps brighten the skin with complete care and repairs 
          sun damage effortlessly. To use Bekhup Ubtan Face Wash, here are the
           detailed steps: Step 1: Apply an adequate amount of Ubtan Face Wash on
            wet or damp skin. Step 2: Massage gently with your ﬁngertips in a circular
             motion. Step 3:
         Rinse oﬀ with water and pat dry. Follow it up with a moisturizer.`
    },
    {
        id:8,
        Que:"Is Bekhup Ubtan face wash good for dry skin?",
        Ans:`Bekhup Tan Removal face wash with turmeric &amp; saffron
         is best for soothing dry and dull skin. It removes 
        uneven sun tan, repairs damage, and brightens the skin tone.`
    },
    {
        id:9,
        Que:"Will this ubtan Bekhup face wash make my skin dry?",
        Ans:`Use a moisturizer for your skin after cleansing with a natural face wash.`
    },
    {
        id:10,
        Que:"How Often Should I Use this Best tan removal face wash?",
        Ans:`Use this Bekhup saffron face wash regularly, twice a day. This tan 
        removal face wash gives best results when get used consistently.`
    },
    {
        id:11,
        Que:"Will the Beads in this Bekhup tan face wash damage my sensitive skin?",
        Ans:"No, this face wash is made with natural ingredients, and Walnut provides gentle exfoliation."
    },
    {
        id:12,
        Que:"Will this Tan Removal Face Wash Make My Skin Clear?",
        Ans:`Yes. Ubtan Face wash for tan removal With its antioxidant and 
        anti-inflammatory properties, Turmeric is an age-old acne preventative and
         hence will help clear your skin effectively. Bekhup 
        ubtan natural with turmeric &amp; saffron face wash will work effectively.`
    },
    {
        id:13,
        Que:"What is the Bekhup ubtan face wash price in india?",
        Ans:`The Bekhup ubtan face wash 100ml price is pocket friendly.
         This Bekhup turmeric face wash costs is Rs.249. And the Bekhup ubtan face wash 
        uses all the natural ingredients full of natural benefits.`
    },
    {
        id:14,
        Que:"Is this ubtan face wash Bekhup free sulfates and toxins?",
        Ans:`Bekhup Ubtan Natural Face Wash does not contain harmful chemicals 
        like SLES, Sulfates, Phthalates, and Artificial Fragrances. Also Bekhup
         ubtan face wash benefits
         your skin because it contains natural ingredients.`
    },
    {
        id:15,
        Que:"Can teenagers use this Bekhup turmeric face wash?",
        Ans:`Absolutely, Face wash turmeric can be used by anyone above the age
         of 15 years as it is made with the best natural and toxin-free
         ingredients, which are ideal for young skin.`
    },
    {
        id:16,
        Que:"What are the Ingredients of Bekhup Ubtan face wash?",
        Ans:`TheBekhup Haldi Ubtan face wash contains Turmeric, 
        Walnut Beads, Saﬀron and Carrot Seed Oil.`
    },
    {
        id:16,
        Que:"Is Bekhup Ubtan face wash good for oily skin?",
        Ans:`Bekhup ubtan face wash is good for oily skin.
         It contains contains many vitamins and
         antioxidants that are beneficial for the all skin types.`
    }
]