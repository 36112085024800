import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  mob: "",
  otp: "",
  pageStatus: 0,
  tabStatus:1,
  isAuth: JSON.parse(localStorage.getItem("loginData"))
    ? JSON.parse(localStorage.getItem("loginData")).isAuth
    : false,
  loginData: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData"))
    : "",
  clientid: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData"))._id
    : "",

  email: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData")).Email
    : "",
  name: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData")).Name
    : "",
  address: localStorage.getItem("address")
    ? JSON.parse(localStorage.getItem("address")).slice().reverse()
    : [],
  currentAddress: localStorage.getItem("currentAddress")
    ? JSON.parse(localStorage.getItem("currentAddress"))
    : "",
  profilepic: "",
  paymentType: "CashOnDelivery",
  clientWalletAmount: 0,
  profilepicLoading: true,
  isClientLoading: true,
  isAgentLoading: true,
  clientLoading: true,
  addressmodel: false,
  editaddressmodel: false,
  walletUse: false,

  showMassage: false,
  showGroccery: false,
  massageText: "",
};

export const loginAuthentication = createAsyncThunk(
  "Athentication/loginAuthentication",
  async (formdetails, thunkAPI) => {
    let resp = {
      success: false,
      message: "new Mobile",
    };
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const mob = formdetails.number;
      const otp = formdetails.OTP;

      const url = `https://mobicomm.dove-sms.com/submitsms.jsp?user=SPAGYRIC&key=6389d561b6XX&mobile=+91${mob}&message=Your One Time OTP for Login is ${otp} www.bekhup.com MOON SPAGYRIC PHARMA.&senderid=SHUJAK&accusage=1&entityid=1701171773902682770&tempid=1707171775234216335`;

      try {
        const res = await fetch(url);
        res
          .json()
          .then((res) => res)
          .catch((err) => console.log(err));
      } catch (error) {}

      resp = await axios.get(url);
      console.log(resp);

      return resp;
    } catch (error) {
      return error;
    }
  }
);

export const getClientByMob = createAsyncThunk(
  "user/getClientByMob",
  async (formData, thunkAPI) => {
    let resp = {
      success: false,
      message: "user not found",
    };
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/client/loginclient`;
      resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const clientRegistation = createAsyncThunk(
  "Athentication/clientRegistation",
  async (formData, thunkAPI) => {
    try {
      const config = {
        maxBodyLength: Infinity,
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/client/new`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("client Not create");
    }
  }
);

export const clientUpdate = createAsyncThunk(
  "Athentication/clientUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/client/Clientid/${formData.claintid}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("client Not create");
    }
  }
);

export const walletTransitionPost = createAsyncThunk(
  "Athentication/walletTransitionPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/wallet/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Not create");
    }
  }
);

const AthenticationSlice = createSlice({
  name: "Athentication",
  initialState,
  reducers: {
    changeTabStatus(state, action) {
      state.tabStatus=action.payload
    },
    LoginOtp(state, action) {
      state.mob = action.payload.number;
      state.otp = action.payload.OTP;
      state.otpLogin = action.payload.OTP;
      state.pageStatus = 1;
    },
    paymentTypeChange(state, action) {
      state.paymentType = action.payload;
    },
    pageStatusChange(state, action) {
      state.pageStatus = action.payload;
    },
    addressModelStatus(state, action) {
      state.addressmodel = action.payload;
    },
    editaddressModelStatus(state, action) {
      state.editaddressmodel = action.payload;
    },
    walletUpdate(state, action) {
      state.loginData.WalletBalance = action.payload;
    },
    walletUpdateAfterOrder(state, action) {
      state.loginData.WalletBalance = action.payload;
      localStorage.setItem("loginData", JSON.stringify(state.loginData));
    },
    walletUseUpdate(state, action) {
      state.walletUse = action.payload;
    },
    noaddressUpdate(state, action) {
      localStorage.removeItem("address");
    },
    showGroceryStatus(state, action) {
      // state.showGroccery = action.payload.superMartShown;
      // state.massageText = action.payload.MassageText;
      // state.showMassage = action.payload.massageShown;
    },
    signin(state, action) {
      state.isAuth = action.payload.isAuth;
      state.clientid = action.payload._id;
      state.email = action.payload.Email;
      state.name = action.payload.Name;
      state.loginData = action.payload;
      state.address = action.payload.Addresses;
      if (state.address.length >= 1 && state.currentAddress === "") {
        state.currentAddress = state.address[0];
      }
      localStorage.setItem("loginData", JSON.stringify(state.loginData));
      localStorage.setItem("address", JSON.stringify(state.address));
      localStorage.setItem(
        "currentAddress",
        JSON.stringify(state.currentAddress)
      );
    },
    currAdress(state, action) {
      state.currentAddress = action.payload;
      localStorage.setItem(
        "currentAddress",
        JSON.stringify(state.currentAddress)
      );
    },
    updateAddress(state, action) {
      state.address = action.payload;
      state.currentAddress = state.address[0];
      localStorage.setItem("address", JSON.stringify(state.address));
      localStorage.setItem(
        "currentAddress",
        JSON.stringify(state.currentAddress)
      );
    },
    signout(state, action) {
      localStorage.removeItem("loginData");
      localStorage.removeItem("address");
      localStorage.removeItem("currentAddress");
      state.loginData.isAuth = false;
      state.isAuth = false;
      state.clientid = "";
      state.currentAddress = "";
      state.address = [];
    },
  },
  extraReducers: {},
});

export const {
  LoginOtp,
  pageStatusChange,
  signin,
  currAdress,
  signout,
  addressModelStatus,
  updateAddress,
  editaddressModelStatus,
  walletUpdate,
  walletUseUpdate,
  walletUpdateAfterOrder,
  paymentTypeChange,
  noaddressUpdate,
  showGroceryStatus,changeTabStatus
} = AthenticationSlice.actions;
export const authActions = AthenticationSlice.actions;
export default AthenticationSlice.reducer;
