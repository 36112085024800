import React, { useEffect, useState } from "react";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { addtoCart, decrementCart } from "../../redux/cart/CartSlice";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";
import { MdFormatListBulletedAdd } from "react-icons/md";

const HotProducts = () => {
  const {
    groceryHotProduct,
    CartItems,
    Cartloading,
    ProductLoading,
    groceryProduct,
  } = useSelector((state) => state.Cart);

  const dispatch = useDispatch();

  const [hotProducts, setHotProducts] = useState([]);
  const [protest, setProtest] = useState(false);

  const [visible, setVisible] = useState(false);
  const [curritemIndex, setcurritemIndex] = useState(0);
  const [currentProduct, setCurrentProduct] = useState("");

  const optionsProductset = (product, itemIndex) => {
    setCurrentProduct(product);
    setcurritemIndex(itemIndex);
    setVisible(!visible);
  };

  useEffect(() => {
    try {
      const fetchData = async () => {
        const url = `${Baseurl}/api/v1/grocery/hotproducts`;
        const hotFetchproducts = await axios(url);
        try {
          if (hotFetchproducts.data.success === true) {
            const datafetch = hotFetchproducts.data;
            let hotproducts = datafetch.grocery;
            CartItems.forEach((cartitem) => {
              let itemIndex = -1;
              itemIndex = hotproducts.findIndex(
                (product) => String(product._id) === String(cartitem.ProductId)
              );
              if (itemIndex >= 0) {
                let packIndex = -1;

                packIndex = hotproducts[itemIndex].PackSizes.findIndex(
                  (pack) => String(pack._id) === String(cartitem.packsizeId)
                );

                hotproducts[itemIndex].PackSizes[packIndex].CartQuantity = 1;
                if (packIndex >= 0) {
                  hotproducts[itemIndex].PackSizes[packIndex].CartQuantity = 1;
                }
              }
            });

            setHotProducts(hotproducts);
          }
        } catch (error) {}
      };

      fetchData();
    } catch (error) {}
  }, []);

  const addtocartPress = async (e, hotPro, itemIndex, packIndex) => {
    try {
      let products = [...hotProducts];

      products[itemIndex].PackSizes[packIndex].CartQuantity =
        products[itemIndex].PackSizes[packIndex].CartQuantity + 1;

      setHotProducts([...products]);
      setProtest(!protest);
    } catch (error) {}

    const formData = {
      ProductId: hotPro._id,
      ProductName: hotPro.ItemName,
      CatName: hotPro.Category,
      CatId: hotPro.CatId,
      SubCat: hotPro.SubCat,
      SubCatId: hotPro.SubCatId,
      Brand: hotPro.Brand,
      ItemName: hotPro.ItemName,
      PackSize: hotPro.PackSizes[packIndex].PackSize,
      Description: hotPro.Description,
      ImgUrl: hotPro.PackSizes[packIndex].ImgUrlDesk,
      Price: hotPro.PackSizes[packIndex].SellingPrice,
      Qty: 1,
      TotalAmount: hotPro.PackSizes[packIndex].SellingPrice * 1,
      Mrp: hotPro.PackSizes[packIndex].Mrp,
      TotalPrice: hotPro.PackSizes[packIndex].SellingPrice * 1,
      TotalMrp: hotPro.PackSizes[packIndex].Mrp * 1,
      Discount:
        hotPro.PackSizes[packIndex].Mrp * 1 -
        hotPro.PackSizes[packIndex].SellingPrice * 1,
      Cashback: hotPro.Cashback,
      SellingPrice: hotPro.PackSizes[packIndex].SellingPrice,
      GstSellPrc: hotPro.PackSizes[packIndex].GstSellPrc,
      CostPrc: hotPro.PackSizes[packIndex].CostPrc,
      GstCost: hotPro.PackSizes[packIndex].GstCost,
      packsizeId: hotPro.PackSizes[packIndex]._id,
      maximumQuantity: hotPro.PackSizes[packIndex].maximumQuantity,
    };
    dispatch(addtoCart(formData));
  };

  const decrementtocartPress = async (e, hotPro, itemIndex, packIndex) => {
    try {
      let products = hotProducts;
      products[itemIndex].PackSizes[packIndex].CartQuantity =
        products[itemIndex].PackSizes[packIndex].CartQuantity - 1;

      setHotProducts(products);
    } catch (error) {}

    const formData = {
      ItemName: hotPro.ItemName,
      Category: hotPro.Category,
      CatId: hotPro.CatId,
      SubCat: hotPro.SubCat,
      SubCatId: hotPro.SubCatId,
      Brand: hotPro.Brand,
      PackSize: hotPro.PackSizes[packIndex].PackSize,
      SellingPrice: hotPro.PackSizes[packIndex].SellingPrice,
      GstSellPrc: hotPro.PackSizes[packIndex].GstSellPrc,
      Mrp: hotPro.PackSizes[packIndex].Mrp,
      CostPrc: hotPro.PackSizes[packIndex].CostPrc,
      totalMrp: hotPro.PackSizes[packIndex].Mrp * 1,
      totalPrice: hotPro.PackSizes[packIndex].SellingPrice * 1,
      totalAmount: hotPro.PackSizes[packIndex].SellingPrice * 1,
      GstCost: hotPro.PackSizes[packIndex].GstCost,
      Discount:
        hotPro.PackSizes[packIndex].Mrp * 1 -
        hotPro.PackSizes[packIndex].SellingPrice * 1,
      CartQuantity: 1,
      ImgUrlMbl: hotPro.PackSizes[packIndex].ImgUrlDesk,
      packsizeId: hotPro.PackSizes[packIndex]._id,
      productId: hotPro._id,
      maximumQuantity: hotPro.maximumQuantity,
    };
    dispatch(decrementCart(formData));
  };

  return (
    <>
      {hotProducts.length > 0 ? (
        <>
          <section>
            <div className="container-fluid-lg">
              <div className="title">
                <h2>Hot Products</h2>
                {/* <span className="title-leaf">
                  <svg className="icon-width">
                    <use xlinkHref="https://themes.pixelstrap.com/fastkart/assets/svg/leaf.svg#leaf" />
                  </svg>
                </span> */}
              </div>
              <div className="product-border border-row">
                <div className="slider-6_2 no-arrow">
                  <Swiper
                    // draggable={true}
                    grabCursor={true}
                    navigation={false}
                    pagination={false}
                    mousewheel={false}
                    keyboard={true}
                    modules={[
                      Navigation,
                      Pagination,
                      Mousewheel,
                      Keyboard,
                      // Autoplay,
                    ]}
                    // loop={true}
                    // autoplay={{
                    //   delay: 1500,
                    //   disableOnInteraction: false,
                    //   pauseOnMouseEnter: true,
                    // }}
                    breakpoints={{
                      360: {
                        slidesPerView: 2,
                        spaceBetween: 5,
                      },
                      460: {
                        slidesPerView: 2,
                        spaceBetween: 5,
                      },
                      720: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                      },
                      1080: {
                        slidesPerView: 5,
                        spaceBetween: 7,
                      },
                    }}
                    className="mySwiper"
                  >
                    <>
                      {hotProducts &&
                        hotProducts.slice(3, 6).map((product, itemIndex) => (
                          <SwiperSlide key={product._id}>
                            <div className=" px-0">
                              <div className="product-box wow fadeIn">
                                {product.PackSizes.map((pack, packIndex) =>
                                  pack.Pri === true ? (
                                    <>
                                      <Link to={`/product-info/${product.Url}`}>
                                        <div
                                          className="product-image"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <a>
                                            <img
                                              src={pack.ImgUrlDesk}
                                              className="img-fluid blur-up lazyload"
                                              alt
                                            />
                                          </a>
                                        </div>
                                      </Link>

                                      <div className="product-detail">
                                        <h6 className="sold weight text-content fw-normal">
                                          {product.Brand}
                                        </h6>
                                        <a>
                                          <h6 className="name name-2 h-100">
                                            {product.ItemName}
                                          </h6>
                                        </a>
                                        <h6 className="sold weight text-content fw-normal">
                                          {pack.PackSize}
                                        </h6>
                                        <div className="counter-box">
                                          <h6 className="sold theme-color">
                                            ₹{pack.SellingPrice}
                                            {pack.Mrp === pack.SellingPrice ? (
                                              <> </>
                                            ) : (
                                              <>
                                                <span
                                                  style={{
                                                    color: "#000",
                                                    fontSize: "12px",
                                                    textDecorationLine:
                                                      "line-through",
                                                  }}
                                                >
                                                  ₹{pack.Mrp}
                                                </span>
                                              </>
                                            )}
                                          </h6>
                                          <div className="addtocart_btn">
                                            {product.Multi === true ? (
                                              <>
                                                <button
                                                  className="add-button addcart-button btn buy-button text-light"
                                                  onClick={() =>
                                                    optionsProductset(
                                                      product,
                                                      itemIndex
                                                    )
                                                  }
                                                >
                                                  <span>Options</span>
                                                  <h4 className="showoption">
                                                    Options
                                                  </h4>
                                                </button>
                                              </>
                                            ) : (
                                              <>
                                                {hotProducts[itemIndex]
                                                  .PackSizes[packIndex]
                                                  .CartQuantity > 0 ? (
                                                  <>
                                                    <div className="qty-box cart_qty open">
                                                      <div className="input-group">
                                                        <button
                                                          type="button"
                                                          className="btn qty-left-minus"
                                                          data-type="minus"
                                                          data-field
                                                          onClick={(e) =>
                                                            decrementtocartPress(
                                                              e,
                                                              product,
                                                              itemIndex,
                                                              packIndex
                                                            )
                                                          }
                                                        >
                                                          <i
                                                            className="fa fa-minus"
                                                            aria-hidden="true"
                                                          />
                                                        </button>
                                                        <span className="form-control input-number qty-input">
                                                          {pack.CartQuantity}
                                                        </span>
                                                        <button
                                                          type="button"
                                                          className="btn qty-right-plus"
                                                          data-type="plus"
                                                          data-field
                                                          onClick={(e) =>
                                                            addtocartPress(
                                                              e,
                                                              product,
                                                              itemIndex,
                                                              packIndex
                                                            )
                                                          }
                                                        >
                                                          <i
                                                            className="fa fa-plus"
                                                            aria-hidden="true"
                                                          />
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <button
                                                      className="add-button addcart-button btn buy-button text-light"
                                                      onClick={(e) =>
                                                        addtocartPress(
                                                          e,
                                                          product,
                                                          itemIndex,
                                                          packIndex
                                                        )
                                                      }
                                                    >
                                                      <span>Add</span>
                                                      <i className="fa-solid fa-plus" />
                                                    </button>
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div key={pack._id}></div>
                                    </>
                                  )
                                )}
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                    </>
                  </Swiper>
                </div>
              </div>
            </div>
            <Modal
              size="md"
              isOpen={visible}
              toggle={() => setVisible(!visible)}
              modalTransition={{ timeout: 500 }}
            >
              <ModalBody>
                <div className="col-12 mt-3 ">
                  <h3>{currentProduct.ItemName}</h3>
                  {currentProduct &&
                    currentProduct.PackSizes.map((packsize, packIndex) => (
                      <div
                        className="col-12 d-flex justify-content-between my-2"
                        key={packsize._id}
                      >
                        <div className="col-2">
                          <img
                            src={packsize.ImgUrlDesk}
                            className="img-fluid blur-up lazyload"
                            alt="image"
                            width={200}
                          />
                        </div>
                        <h6
                          className="col-1 d-flex justify-content-center align-items-center "
                          style={{
                            color: "#0e947a",
                            fontSize: "14px",
                            marginLeft: "2vh",
                          }}
                        >
                          {packsize.PackSize}
                        </h6>
                        <h6
                          className="col-3 d-flex justify-content-center align-items-center "
                          style={{
                            color: "#0e947a",
                            fontSize: "14px",
                            marginLeft: "2vh",
                          }}
                        >
                          ₹{packsize.SellingPrice}
                          {packsize.Mrp === packsize.SellingPrice ? (
                            <> </>
                          ) : (
                            <>
                              <span
                                style={{
                                  color: "#000",
                                  fontSize: "12px",
                                  textDecorationLine: "line-through",
                                  marginLeft: "0.5vh",
                                }}
                              >
                                ₹{packsize.Mrp}
                              </span>
                            </>
                          )}
                        </h6>
                        <div className="col-5 d-flex justify-content-end align-items-center">
                          {packsize.CartQuantity > 0 ? (
                            <>
                              <Button
                                variant="contained"
                                color="success"
                                className="mx-1"
                                onClick={(e) =>
                                  decrementtocartPress(
                                    e,
                                    currentProduct,
                                    curritemIndex,
                                    packIndex
                                  )
                                }
                              >
                                -
                              </Button>
                              <h3>{packsize.CartQuantity}</h3>
                              <Button
                                variant="contained"
                                color="success"
                                className="mx-1"
                                onClick={(e) =>
                                  addtocartPress(
                                    e,
                                    currentProduct,
                                    curritemIndex,
                                    packIndex
                                  )
                                }
                              >
                                +
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                variant="contained"
                                color="success"
                                className="mx-1"
                                onClick={(e) =>
                                  addtocartPress(
                                    e,
                                    currentProduct,
                                    curritemIndex,
                                    packIndex
                                  )
                                }
                              >
                                Add
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </ModalBody>
            </Modal>
          </section>{" "}
        </>
      ) : (
        <>
          <div></div>
        </>
      )}
    </>
  );
};

export default HotProducts;
