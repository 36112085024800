import React, { useState } from "react";
import "./AdditionalInfo.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
const AdditionalInfo = ({
  dimensions,
  manufacturer,
  uselisense,
  useNodal,
  usefore,
  useMarket,
  useCountry,
}) => {
  const [click, setClick] = useState(false);
  let handleClick = () => {
    setClick(!click);
  };
  return (
    <>
      <div className="MainPage">
        <div className="Subpage">
          <div className="header">
            <h4 onClick={handleClick}>
              ADDITIONAL INFORMATION{" "}
              {click ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </h4>
          </div>
          {click ? (
            <div className="pages">
              <div className="subpage1">
                {usefore !== "" ? (
                  <>
                    <h6 className="text">BEST BEFORE</h6>
                    <p className="smtext">{usefore}</p>
                  </>
                ) : (
                  <></>
                )}
                {manufacturer !== "" ? (
                  <>
                    <h6 className="text">MANUFACTURER</h6>
                    <p className="smtext">{manufacturer}</p>
                  </>
                ) : (
                  <></>
                )}
                {uselisense !== "" ? (
                  <>
                    <h6 className="text">LICENSE NO</h6>
                    <p className="smtext">{uselisense}</p>
                  </>
                ) : (
                  <></>
                )}

                {useNodal !== "" ? (
                  <>
                    <h6 className="text">NODAL OFFICER DETAIL</h6>
                    <p className="smtext">{useNodal}</p>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="subpage2">
                {dimensions !== "" ? (
                  <>
                    <h6 className="text">DIMENSIONS</h6>
                    <p className="smtext">{dimensions}</p>
                  </>
                ) : (
                  <></>
                )}

                {useMarket !== "" ? (
                  <>
                    <h6 className="text">MARKETED BY</h6>
                    <p className="smtext">{useMarket}</p>
                  </>
                ) : (
                  <></>
                )}
                {useCountry !== "" ? (
                  <>
                    <h6 className="text">COUNTRY OF ORIGIN</h6>
                    <p className="smtext">{useCountry}</p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <>
              <h6 className="text"></h6>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AdditionalInfo;
