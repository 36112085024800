import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { BsHouseDoor } from "react-icons/bs";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "@mui/material";
import { GiConsoleController } from "react-icons/gi";
import { BiMinus } from "react-icons/bi";
import {
  AiOutlineLeft,
  AiOutlinePlus,
  AiOutlineRight,
  AiOutlineHeart,
} from "react-icons/ai";
import { addtoCart, decrementCart } from "../../redux/cart/CartSlice";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";

const CategorySearch = () => {
  const { CartItems, ProductTotal, ProductLoading } = useSelector(
    (state) => state.Cart
  );
  const { categoryTotal } = useSelector((state) => state.Category);

  const [products, setProducts] = useState([]);
  const [dummy, setDummy] = useState([]);

  const [visible, setVisible] = useState(false);
  const [curritemIndex, setcurritemIndex] = useState(0);
  const [currentProduct, setCurrentProduct] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const searchUrl = params.catsearch;
    const singleCat = categoryTotal.find((cat) => {
      return cat.slugUrl === searchUrl;
    });
    const catId = singleCat._id;

    if (!ProductLoading) {
      let categoryproducts = ProductTotal.filter(
        (product) => product.CatId === catId
      );

      let fetchQntyproducts = categoryproducts.map((product) => {
        let cartPackquentity = [];
        let packqnty = {};

        for (let index = 0; index < product.PackSizes.length; index++) {
          const element = product.PackSizes[index];
          packqnty = {
            packId: element._id,
            CartQuantity: 0,
            Pri: element.Pri,
          };
          cartPackquentity = [...cartPackquentity, packqnty];
          cartPackquentity = [...cartPackquentity];
        }
        product = { ...product, cartPackquentity };
        return product;
      });

      CartItems.forEach((cartitem) => {
        let itemIndex = -1;
        itemIndex = fetchQntyproducts.findIndex(
          (product) => String(product._id) === String(cartitem.ProductId)
        );
        if (itemIndex >= 0) {
          let packIndex = -1;

          packIndex = fetchQntyproducts[itemIndex].cartPackquentity.findIndex(
            (pack) => String(pack.packId) === String(cartitem.packsizeId)
          );

          fetchQntyproducts[itemIndex].cartPackquentity[
            packIndex
          ].CartQuantity = 1;
          if (packIndex >= 0) {
            fetchQntyproducts[itemIndex].cartPackquentity[
              packIndex
            ].CartQuantity = 1;
          }
        }
      });
      setProducts(fetchQntyproducts);
    }
  }, [params.catsearch, ProductLoading]);

  const optionsProductset = (product, itemIndex) => {
    setCurrentProduct(product);
    setcurritemIndex(itemIndex);
    setVisible(!visible);
  };

  const addtocartPress = async (e, hotPro, itemIndex, packIndex) => {
    try {
      let productsall = products;
      productsall[itemIndex].cartPackquentity[packIndex].CartQuantity =
        productsall[itemIndex].cartPackquentity[packIndex].CartQuantity + 1;
      setDummy(productsall);
      setProducts(productsall);
    } catch (error) {}

    const formData = {
      ProductId: hotPro._id,
      ProductName: hotPro.ItemName,
      CatName: hotPro.Category,
      CatId: hotPro.CatId,
      SubCat: hotPro.SubCat,
      SubCatId: hotPro.SubCatId,
      Brand: hotPro.Brand,
      ItemName: hotPro.ItemName,
      PackSize: hotPro.PackSizes[packIndex].PackSize,
      Description: hotPro.Description,
      ImgUrl: hotPro.PackSizes[packIndex].ImgUrlMbl,
      Price: hotPro.PackSizes[packIndex].SellingPrice,
      Qty: 1,
      TotalAmount: hotPro.PackSizes[packIndex].SellingPrice * 1,
      Mrp: hotPro.PackSizes[packIndex].Mrp,
      TotalPrice: hotPro.PackSizes[packIndex].SellingPrice * 1,
      TotalMrp: hotPro.PackSizes[packIndex].Mrp * 1,
      Discount:
        hotPro.PackSizes[packIndex].Mrp * 1 -
        hotPro.PackSizes[packIndex].SellingPrice * 1,
      Cashback: hotPro.Cashback,
      SellingPrice: hotPro.PackSizes[packIndex].SellingPrice,
      GstSellPrc: hotPro.PackSizes[packIndex].GstSellPrc,
      CostPrc: hotPro.PackSizes[packIndex].CostPrc,
      GstCost: hotPro.PackSizes[packIndex].GstCost,
      packsizeId: hotPro.PackSizes[packIndex]._id,
      maximumQuantity: hotPro.PackSizes[packIndex].maximumQuantity,
    };
    dispatch(addtoCart(formData));
  };

  const decrementtocartPress = async (e, hotPro, itemIndex, packIndex) => {
    try {
      let productsall = [...products];
      productsall[itemIndex].cartPackquentity[packIndex].CartQuantity =
        productsall[itemIndex].cartPackquentity[packIndex].CartQuantity - 1;
      setDummy(productsall);
      setProducts([...productsall]);
    } catch (error) {}

    const formData = {
      ItemName: hotPro.ItemName,
      Category: hotPro.Category,
      CatId: hotPro.CatId,
      SubCat: hotPro.SubCat,
      SubCatId: hotPro.SubCatId,
      Brand: hotPro.Brand,
      PackSize: hotPro.PackSizes[packIndex].PackSize,
      SellingPrice: hotPro.PackSizes[packIndex].SellingPrice,
      GstSellPrc: hotPro.PackSizes[packIndex].GstSellPrc,
      Mrp: hotPro.PackSizes[packIndex].Mrp,
      CostPrc: hotPro.PackSizes[packIndex].CostPrc,
      totalMrp: hotPro.PackSizes[packIndex].Mrp * 1,
      totalPrice: hotPro.PackSizes[packIndex].SellingPrice * 1,
      totalAmount: hotPro.PackSizes[packIndex].SellingPrice * 1,
      GstCost: hotPro.PackSizes[packIndex].GstCost,
      Discount:
        hotPro.PackSizes[packIndex].Mrp * 1 -
        hotPro.PackSizes[packIndex].SellingPrice * 1,
      CartQuantity: 1,
      ImgUrlMbl: hotPro.PackSizes[packIndex].ImgUrlMbl,
      packsizeId: hotPro.PackSizes[packIndex]._id,
      productId: hotPro._id,
      maximumQuantity: hotPro.maximumQuantity,
    };
    dispatch(decrementCart(formData));
  };

  return (
    <>
      {ProductLoading ? (
        <>
          <div className="d-flex justify-content-center loadingMain">
            <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
              Product is being fetched
            </h4>
            <BeatLoader
              color={"#36d7b7"}
              loading={ProductLoading}
              size={10}
              className="mt-2 mx-2"
            />
          </div>
        </>
      ) : (
        <>
          {products.length > 0 ? (
            <>
              {" "}
              <section className="wishlist-section section-b-space">
                <div className="container-fluid-lg">
                  <div className="row g-sm-3 g-2">
                    {products.map((product, itemIndex) => (
                      <div
                        className="col-xxl-2 col-lg-3 col-md-4 col-6 product-box-contain p-1"
                        key={itemIndex}
                      >
                        {product.PackSizes.map((pack, packIndex) =>
                          pack.Pri === true ? (
                            <>
                              <div className="product-box-3 h-100">
                                <div className="product-header">
                                  <div className="product-image">
                                    <Link>
                                      <img
                                        src={pack.ImgUrlDesk}
                                        className="img-fluid blur-up lazyload"
                                        alt="imag"
                                      />
                                    </Link>
                                  </div>
                                </div>
                                <div className="product-footer">
                                  <div className="product-detail">
                                    <span className="span-name">
                                      {product.Brand}
                                    </span>
                                    <Link>
                                      <h5 className="name">
                                        {product.ItemName.slice(0, 50)}
                                      </h5>
                                    </Link>
                                    <h6 className="unit mt-1">
                                      {" "}
                                      {pack.PackSize}
                                    </h6>
                                    <h5 className="price">
                                      <span className="theme-color">
                                        ₹{pack.SellingPrice}
                                      </span>
                                      {pack.Mrp === pack.SellingPrice ? (
                                        <> </>
                                      ) : (
                                        <>
                                          <del>₹{pack.Mrp}</del>
                                        </>
                                      )}
                                    </h5>
                                    <div className="add-to-cart-box bg-white mt-2">
                                      {pack.OutOfStack === true ? (
                                        <>
                                          <button className="btn btn-add-cart addcart-button">
                                            out of stock
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {product.Multi === true ? (
                                            <>
                                              <button
                                                className="btn btn-add-cart addcart-button"
                                                onClick={() =>
                                                  optionsProductset(
                                                    product,
                                                    itemIndex
                                                  )
                                                }
                                              >
                                                Options
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              {products[itemIndex]
                                                .cartPackquentity[packIndex]
                                                .CartQuantity > 0 ? (
                                                <>
                                                  <div className="cart_qty qty-box open">
                                                    <div className="input-group bg-white">
                                                      <button
                                                        type="button"
                                                        className="qty-left-minus bg-gray"
                                                        data-type="minus"
                                                        data-field
                                                        onClick={(e) =>
                                                          decrementtocartPress(
                                                            e,
                                                            product,
                                                            itemIndex,
                                                            packIndex
                                                          )
                                                        }
                                                      >
                                                        <i
                                                          className="fa fa-minus"
                                                          aria-hidden="true"
                                                        />
                                                      </button>
                                                      <input
                                                        className="form-control input-number qty-input"
                                                        type="text"
                                                        name="quantity"
                                                        defaultValue={0}
                                                        value={
                                                          products[itemIndex]
                                                            .cartPackquentity[
                                                            packIndex
                                                          ].CartQuantity
                                                        }
                                                        readonly="readonly"
                                                      />
                                                      {11 ===
                                                      products[itemIndex]
                                                        .cartPackquentity[
                                                        packIndex
                                                      ].CartQuantity ? (
                                                        <>
                                                          <button
                                                            type="button"
                                                            className="qty-right-plus bg-gray"
                                                            data-type="plus"
                                                            data-field
                                                          >
                                                            <i
                                                              className="fa fa-plus"
                                                              aria-hidden="true"
                                                            />
                                                          </button>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {" "}
                                                          <button
                                                            type="button"
                                                            className="qty-right-plus bg-gray"
                                                            data-type="plus"
                                                            data-field
                                                            onClick={(e) =>
                                                              addtocartPress(
                                                                e,
                                                                product,
                                                                itemIndex,
                                                                packIndex
                                                              )
                                                            }
                                                          >
                                                            <i
                                                              className="fa fa-plus"
                                                              aria-hidden="true"
                                                            />
                                                          </button>
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <button
                                                    className="btn btn-add-cart addcart-button"
                                                    onClick={(e) =>
                                                      addtocartPress(
                                                        e,
                                                        product,
                                                        itemIndex,
                                                        packIndex
                                                      )
                                                    }
                                                  >
                                                    Add
                                                    <span className="add-icon bg-light-gray">
                                                      <i className="fa-solid fa-plus" />
                                                    </span>
                                                  </button>
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div key={packIndex}></div>
                            </>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <Modal
                  size="md"
                  // centered
                  isOpen={visible}
                  toggle={() => setVisible(!visible)}
                  modalTransition={{ timeout: 500 }}
                >
                  <ModalBody>
                    <h3>{currentProduct.ItemName}</h3>
                    <h5>{currentProduct.Brand}</h5>
                    <div className="col-12 mt-3 ">
                      {currentProduct &&
                        currentProduct.PackSizes.map((packsize, packIndex) => (
                          <div
                            className="col-12 d-flex justify-content-between my-2"
                            key={packsize._id}
                          >
                            <div className="col-2">
                              <img
                                src={packsize.ImgUrlDesk}
                                className="img-fluid blur-up lazyload"
                                alt="image"
                                width={200}
                              />
                            </div>
                            <h6
                              className="col-1 d-flex justify-content-center align-items-center "
                              style={{
                                color: "#0e947a",
                                fontSize: "14px",
                                marginLeft: "2vh",
                              }}
                            >
                              {packsize.PackSize}
                            </h6>
                            <h6
                              className="col-3 d-flex justify-content-center align-items-center "
                              style={{
                                color: "#0e947a",
                                fontSize: "14px",
                                marginLeft: "2vh",
                              }}
                            >
                              ₹{packsize.SellingPrice}
                              {packsize.Mrp === packsize.SellingPrice ? (
                                <> </>
                              ) : (
                                <>
                                  <span
                                    style={{
                                      color: "#000",
                                      fontSize: "12px",
                                      textDecorationLine: "line-through",
                                      marginLeft: "0.5vh",
                                    }}
                                  >
                                    ₹{packsize.Mrp}
                                  </span>
                                </>
                              )}
                            </h6>
                            <div className="col-3 d-flex justify-content-end align-items-center">
                              {packsize.OutOfStack === true ? (
                                <>
                                  {" "}
                                  <Button
                                    variant="contained"
                                    style={{
                                      color: "#fff",
                                      background: "#0e947a",
                                      // fontSize:"15px"
                                    }}
                                    className="mx-1"
                                  >
                                    out of stock
                                  </Button>
                                </>
                              ) : (
                                <>
                                  {currentProduct.cartPackquentity[packIndex]
                                    .CartQuantity > 0 ? (
                                    <>
                                      <div className="addtocart_btn">
                                        <div className="qty-box cart_qty open">
                                          <div className="input-group">
                                            <button
                                              type="button"
                                              className="btn qty-left-minus"
                                              data-type="minus"
                                              data-field
                                              onClick={(e) =>
                                                decrementtocartPress(
                                                  e,
                                                  currentProduct,
                                                  curritemIndex,
                                                  packIndex
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-minus"
                                                aria-hidden="true"
                                              />
                                            </button>

                                            <input
                                              className="form-control input-number qty-input mx-2"
                                              type="text"
                                              name="quantity"
                                              defaultValue={0}
                                              value={
                                                currentProduct.cartPackquentity[
                                                  packIndex
                                                ].CartQuantity
                                              }
                                              readonly="readonly"
                                            />

                                            {11 ===
                                            currentProduct.cartPackquentity[
                                              packIndex
                                            ].CartQuantity ? (
                                              <>
                                                {" "}
                                                <button
                                                  type="button"
                                                  className="btn qty-left-plus"
                                                  data-type="plus"
                                                  data-field
                                                >
                                                  <i
                                                    className="fa fa-plus"
                                                    aria-hidden="true"
                                                  />
                                                </button>
                                              </>
                                            ) : (
                                              <>
                                                {" "}
                                                <button
                                                  type="button"
                                                  className="btn qty-left-plus"
                                                  data-type="plus"
                                                  data-field
                                                  onClick={(e) =>
                                                    addtocartPress(
                                                      e,
                                                      currentProduct,
                                                      curritemIndex,
                                                      packIndex
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className="fa fa-plus"
                                                    aria-hidden="true"
                                                  />
                                                </button>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        variant="contained"
                                        style={{
                                          color: "#fff",
                                          background: "#0e947a",
                                        }}
                                        className="mx-1"
                                        onClick={(e) =>
                                          addtocartPress(
                                            e,
                                            currentProduct,
                                            curritemIndex,
                                            packIndex
                                          )
                                        }
                                      >
                                        Add
                                        <span className="mx-1">
                                          <i className="fa-solid fa-plus" />
                                        </span>
                                      </Button>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </ModalBody>
                </Modal>
              </section>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default CategorySearch;
