import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader } from "reactstrap";
import axios from "axios";

import { Baseurl } from "../../../../config/BaseUrl";
import {
  editaddressModelStatus,
  currAdress,
  updateAddress,
} from "../../../../redux/athentication/Athentication";

const EditAddress = ({ addressobject }) => {
  const { isAuth, loginData, clientid, address } = useSelector(
    (store) => store.Athentication
  );

  const dispatch = useDispatch();
  const [model, setModel] = useState();

  const [addressId, setAddressId] = useState("");
  const [fname, setFname] = useState("");
  const [mobile, setMobile] = useState("");
  const [number, setNumber] = useState("");
  const [altmobile, setAltMobile] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [street, setStreet] = useState("");
  const [addres, setAddress] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPinCode] = useState("");
  const [pincodecon, setPinCodecon] = useState("");
  const [addressType, setAddressType] = useState("Home");
  const [errormassage, setErrormassage] = useState("");
  const [pinerrormassage, setPinErrormassage] = useState("");
  const [landmark, setLandmark] = useState("");
  const [areaDept, setAreaDept] = useState("");
  const [areaArray, setAreaArray] = useState([]);
  const [mainerrormassage, setMainErrormassage] = useState("");

  const handleChange = (e) => {
    const numbers = e.target.value.replace(/\D/g, "");
    setErrormassage("");
    setNumber(numbers);
    if (numbers.length === 10) {
      setMobile(numbers);
      setErrormassage("");
    } else {
      setMobile("");
      setErrormassage("please enter currect number");
    }
  };
  const pincodehandleChange = (e) => {
    const pincodevalue = e.target.value.replace(/\D/g, "");
    setPinErrormassage("");
    setPinCode(pincodevalue);
    if (pincodevalue.length === 6) {
      setPinCodecon(pincodevalue);
      setPinErrormassage("");
    } else {
      setPinCodecon("");
      setPinErrormassage("please enter currect pincode");
    }
  };

  const addresPinarray = [
    { pinCode: "831001", id: 1 },
    { pinCode: "831002", id: 2 },
    { pinCode: "831003", id: 3 },
    { pinCode: "831004", id: 4 },
    { pinCode: "831005", id: 5 },
    { pinCode: "831006", id: 6 },
    { pinCode: "831007", id: 7 },
    { pinCode: "831008", id: 8 },
    { pinCode: "831009", id: 9 },
    { pinCode: "831010", id: 10 },
    { pinCode: "831011", id: 11 },
    { pinCode: "831012", id: 12 },
    { pinCode: "831013", id: 13 },
    { pinCode: "831014", id: 14 },
    { pinCode: "831015", id: 15 },
    { pinCode: "831016", id: 16 },
    { pinCode: "831017", id: 17 },
    { pinCode: "831018", id: 18 },
    { pinCode: "831019", id: 19 },
    { pinCode: "832109", id: 20 },
    { pinCode: "832110", id: 21 },
  ];
  const addresCityarray = [
    { pinCode: "831001", cityname: "Sakchi", id: 1 },
    { pinCode: "831001", cityname: "Bistupur", id: 2 },
    { pinCode: "831001", cityname: "Sonari", id: 3 },
    { pinCode: "831001", cityname: "Baradwari", id: 4 },
    { pinCode: "831001", cityname: "Dhatkidih", id: 5 },
    { pinCode: "831001", cityname: "XLRI Jamshedpur", id: 6 },

    { pinCode: "831002", cityname: "Parsudihr", id: 7 },

    { pinCode: "831003", cityname: "Golmuri", id: 8 },
    { pinCode: "831003", cityname: "Cable town", id: 9 },
    { pinCode: "831003", cityname: "Sitaramdera", id: 10 },
    { pinCode: "831003", cityname: "Nildih", id: 11 },
    { pinCode: "831003", cityname: "Golmuri", id: 12 },

    { pinCode: "831004", cityname: "Telco", id: 13 },
    { pinCode: "831004", cityname: "Ghorabandha", id: 14 },
    { pinCode: "831004", cityname: "Kharangajhar", id: 15 },
    { pinCode: "831004", cityname: "Azad market", id: 16 },
    { pinCode: "831004", cityname: "Kalimati, sakchi", id: 17 },
    { pinCode: "831004", cityname: "Birsanagar", id: 18 },
    { pinCode: "831004", cityname: "chhota govindpur", id: 19 },

    { pinCode: "831005", cityname: "kadma", id: 20 },
    { pinCode: "831005", cityname: "Uliyan", id: 21 },

    { pinCode: "831006", cityname: "Jugsalai", id: 22 },

    { pinCode: "831007", cityname: "Burmamines", id: 23 },

    { pinCode: "831009", cityname: "Agrico", id: 24 },
    { pinCode: "831009", cityname: "Bhalubasa", id: 25 },
    { pinCode: "831009", cityname: "Sidhgora", id: 26 },
    { pinCode: "831009", cityname: "Bhuiyadih", id: 27 },

    { pinCode: "831011", cityname: "Adarsh Nagar", id: 28 },
    { pinCode: "831011", cityname: "sonari", id: 29 },

    { pinCode: "831012", cityname: "Mango", id: 30 },
    { pinCode: "831012", cityname: "Dimna", id: 31 },
    { pinCode: "831012", cityname: "sahara city, Mango", id: 32 },

    { pinCode: "831013", cityname: "Adityapur-1", id: 33 },

    { pinCode: "831015", cityname: "Chhota Govindpur", id: 34 },

    { pinCode: "831017", cityname: "Vijaya garden, Baridih", id: 35 },
    { pinCode: "831017", cityname: "Baridih colony", id: 36 },
    { pinCode: "831017", cityname: "Birsanagar", id: 37 },
    { pinCode: "831017", cityname: "Baridih", id: 38 },

    { pinCode: "831018", cityname: "MGM Medical college", id: 39 },

    { pinCode: "831019", cityname: "Birsanagar", id: 40 },

    { pinCode: "832109", cityname: "Adityapur", id: 41 },

    { pinCode: "832110", cityname: "Azadnagar, mango", id: 42 },
  ];

  const selectPincode = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setPinCodecon(text);
  };
  const selectcity = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCity(text);
  };

  useEffect(() => {
    const filterarreabypin = addresCityarray.filter(
      (pin) => pin.pinCode === pincodecon
    );

    setAreaArray(filterarreabypin);
  }, [pincodecon]);

  useEffect(() => {
    if (isAuth) {
      setAddressId(addressobject.address._id);
      setFname(addressobject.address.AName);
      setMobile(addressobject.address.Number);
      setNumber(addressobject.address.Number);
      setAltMobile(addressobject.address.Mobile);
      setHouseNo(addressobject.address.HNo);
      setStreet(addressobject.address.StreetDet);
      setAddress(addressobject.address.Address);
      // setArea(addressobject.address)
      setCity(addressobject.address.City);
      setState(addressobject.address.State);
      setPinCode(addressobject.address.Pincode);
      setPinCodecon(addressobject.address.Pincode);
      setAddressType(addressobject.address.Type);
      setLandmark(addressobject.address.LandMark);
      setAreaDept(addressobject.address.AreaDet);
    }
  }, []);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setMainErrormassage("");
    let addresss = [...address];
    if (mobile !== "" && pincodecon !== "") {
      try {
        const formData = {
          AName: fname.charAt(0).toUpperCase() + fname.slice(1).toLowerCase(),
          Number: mobile,
          Mobile: altmobile,
          Pincode: pincodecon,
          State: state,
          City: city,
          HNo: houseNo,
          StreetDet: street,
          AreaDet: areaDept,
          LandMark: landmark,
          Address: addres,
          Type: addressType,
          ClientId: clientid,
          addressId: addressId,
        };
        const config = {
          Headers: { "Content-Type": "application/json" },
        };

        const url = `${Baseurl}/api/v1/client/updateclientsingleaddress`;
        const addAddress = await axios.put(url, formData, config);

        if (addAddress.data.success) {
          const lastaddress = addAddress.data.client;
          const currentAddress = lastaddress.Addresses;
          dispatch(updateAddress(currentAddress));
          dispatch(editaddressModelStatus(false));
        }
      } catch (error) {}
    } else {
      setMainErrormassage("please enter currect values");
    }
  };

  return (
    <>
      <div className="header d-flex justify-content-center align-items-center">
        <h5 className="modal-title">
          <b>Edit your address</b>
        </h5>
        {/* <Modal size="md" isOpen={model} toggle={() => setModel(!model)}>
            <ModalHeader>
              <AddAddress />
            </ModalHeader>
          </Modal> */}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => dispatch(editaddressModelStatus(false))}
        >
          <i className="fa-solid fa-xmark" />
        </button>
      </div>
      <div className="modal-body">
        <div className="row g-4">
          <div className="col-xxl-12 mt-4 m-2">
            <form>
              <div className="form-floating theme-form-floating">
                <input
                  type="text"
                  className="form-control opacity-75"
                  id="pname"
                  placeholder=" "
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                  required
                  style={{ textTransform: "capitalize" }}
                />
                <label htmlFor="pname">Full Name</label>
              </div>
            </form>
          </div>

          <div className="row-xxl-12 d-flex">
            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=" "
                    name="mobile"
                    id="mobile"
                    maxLength={10}
                    minLength={10}
                    value={number}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  <label htmlFor="mobile">Mobile Number</label>
                </div>
              </form>
              <p style={{ color: "red" }}>{errormassage}</p>
            </div>

            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=" "
                    name="mobile"
                    id="mobile"
                    maxLength={10}
                    minLength={10}
                    value={altmobile}
                    readOnly="readOnly"
                    onChange={(e) => setAltMobile(e.target.value)}
                  />
                  <label htmlFor="mobile">Alternative Mobile Number</label>
                </div>
              </form>
            </div>
          </div>

          <div className="row-xxl-12 d-flex">
            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="text"
                    className="form-control opacity-75"
                    id="pname"
                    placeholder=" "
                    value={houseNo}
                    onChange={(e) => setHouseNo(e.target.value)}
                    required
                    style={{ textTransform: "capitalize" }}
                  />
                  <label htmlFor="pname">House No.</label>
                </div>
              </form>
            </div>
            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="text"
                    className="form-control opacity-75"
                    id="pname"
                    placeholder=" "
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    required
                    style={{ textTransform: "capitalize" }}
                  />
                  <label htmlFor="pname">Street</label>
                </div>
              </form>
            </div>
          </div>

          <div className="col-xxl-12 mt-2">
            <form>
              <div className="form-floating mb-4 theme-form-floating">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="address"
                  style={{ height: 75, textTransform: "capitalize" }}
                  defaultValue={""}
                  value={addres}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
                <label htmlFor="address">Enter Address</label>
              </div>
            </form>
          </div>

          <div className="row-xxl-12 d-flex">
            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="text"
                    className="form-control opacity-75"
                    id="landmark"
                    placeholder=" "
                    value={landmark}
                    onChange={(e) => setLandmark(e.target.value)}
                    style={{ textTransform: "capitalize" }}
                  />
                  <label htmlFor="landmark">Landmark</label>
                </div>
              </form>
            </div>
            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="text"
                    className="form-control opacity-75"
                    id="areadept"
                    placeholder=" "
                    value={areaDept}
                    required
                    onChange={(e) => setAreaDept(e.target.value)}
                    style={{ textTransform: "capitalize" }}
                  />
                  <label htmlFor="areadept">Area Dept</label>
                </div>
              </form>
            </div>
          </div>

          <div className="row-xxl-12 d-flex">
            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="text"
                    className="form-control opacity-75"
                    id="pname"
                    placeholder=" "
                    value={area}
                    required
                    onChange={(e) => setArea(e.target.value)}
                    style={{ textTransform: "capitalize" }}
                  />
                  <label htmlFor="pname">Area</label>
                </div>
              </form>
            </div>
            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="text"
                    className="form-control opacity-75"
                    id="state"
                    placeholder=" "
                    value={state}
                    required
                    onChange={(e) => setState(e.target.value)}
                    style={{ textTransform: "capitalize" }}
                  />
                  <label htmlFor="state">State</label>
                </div>
              </form>
            </div>
            {/* <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="text"
                    className="form-control opacity-75"
                    id="pname"
                    placeholder=" "
                    value={city}
                    required
                    onChange={(e) => setCity(e.target.value)}
                    style={{ textTransform: "capitalize" }}
                  />
                  <label htmlFor="pname">City</label>
                </div>
              </form>
            </div> */}
          </div>

          <div className="row-xxl-12 d-flex ">
            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="text"
                    className="form-control opacity-75"
                    id="pname"
                    placeholder=" "
                    value={pincodecon}
                    required
                    minLength={6}
                    maxLength={6}
                    onChange={(e) =>
                      setPinCodecon(e.target.value.replace(/\D/g, ""))
                    }
                    style={{ textTransform: "capitalize" }}
                  />
                  <label htmlFor="pname">Pincode</label>
                </div>
              </form>
            </div>
            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="text"
                    className="form-control opacity-75"
                    id="pname"
                    placeholder=" "
                    value={city}
                    required
                    onChange={(e) => setCity(e.target.value)}
                    style={{ textTransform: "capitalize" }}
                  />
                  <label htmlFor="pname">City</label>
                </div>
              </form>
            </div>
          </div>

          <div className="designAdrs">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="Home"
                checked={addressType === "Home"}
                onChange={(e) => {
                  setAddressType("Home");
                }}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Home
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="Office"
                checked={addressType === "Office"}
                onChange={(e) => {
                  setAddressType("Office");
                }}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                Office
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault3"
                value="Others"
                checked={addressType === "Others"}
                onChange={(e) => {
                  setAddressType("Others");
                }}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault3">
                Others
              </label>
            </div>
          </div>
        </div>
      </div>
      <p style={{ color: "red" }}>{mainerrormassage}</p>
      <div className="modal-footer">
        <button
          type="button"
          className="btn bg-danger text-light btn-md"
          data-bs-dismiss="modal"
          onClick={() => dispatch(editaddressModelStatus(false))}
        >
          Close
        </button>
        <button
          type="button"
          className="btn theme-bg-color btn-md text-white"
          data-bs-dismiss="modal"
          onClick={(e) => onSubmitHandler(e)}
        >
          Save Address
        </button>
      </div>
    </>
  );
};

export default EditAddress;
