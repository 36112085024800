import React from "react";
import Data1 from "./Data1";
import { Data } from "./Data";

const Faqs1 = () => {
  return (
    <>
      <div className="Main">
        <div className="subpage">
          <div className="page">
            <h5>FAQs</h5>
          </div>
          <div className="Data">
            {Data.map(({ index, Que, Ans }) => {
              return <Data1 key={index} Que={Que} Ans={Ans} />;
            })}
          </div>
          
        </div>
      </div>
    </>
  );
};

export default Faqs1;
