import React, { useState } from 'react'
import { IoIosArrowUp } from "react-icons/io";
import { MdKeyboardArrowDown } from "react-icons/md";
const Data1 = ({Que,Ans}) => {
    let[state,setState]=useState(false)
    const HandleClick=()=>{
        setState(!state)
    }
  return (
    <>
    <div className="data">
        <div className="data1" onClick={HandleClick}>
        <h4>{Que}</h4>
        <span>{state?<IoIosArrowUp className='icons'/>:<MdKeyboardArrowDown className='icons' />}</span></div>
        {state?<p>{Ans}</p>:" "}
    </div>
    </>
  )
}

export default Data1