import React, { useEffect, useState } from "react";
import "swiper/swiper-bundle.min.css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Mousewheel } from "swiper";

const Results = (props) => {
  const [result, setResult] = useState([]);

  useEffect(() => {
    const resultShow = props.result;
    setResult(resultShow);
  }, [props.result]);

  return (
    <>
      {result.length > 0 ? (
        <>
          {" "}
          <div className="resultMain" style={{width:"100%"}}>
            <h2>Results of Ubtan Face Wash</h2>
            <div className="swiper w-100 mt-2" >
              <Swiper
                slidesPerView={3}
                spaceBetween={10}
                freeMode={true}
                modules={[Pagination, Mousewheel, Autoplay]}
                loop={true}
                breakpoints={{
                  250: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  460: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  720: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1080: {
                    slidesPerView: 4,
                  },
                }}
                className="mySwiper"
              >
                {result.map((indResult) => (
                  <SwiperSlide>
                    <div className="resultimg w-100">
                      <img
                        className="img-fluid"
                        src={indResult?.product_imgurl}
                        alt={indResult.product_header}
                      />
                      <h1>{indResult?.product_header}</h1>
                      <p className="w-75">{indResult?.product_description}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            {/* <div className="resultList">
        <div className="resultimg">
          <img
            src="assets/images/productDetailsImages/ubtan face wash mamaearth Removes Tan1.jpg"
            alt=""
          />
          <h1>Removes Tan</h1>
          <p>
            The combination of Saffron & Carrot Seed Oil removes tan from the
            upper layer of the skin while restoring natural glow. Indulge in
            the age-old tradition of Ubtan and let your skin rejoice in the
            natural goodness of Turmeric, Saffron & Carrot seed Oil, known
            best for tan removal.
          </p>
        </div>
        <div className="resultimg">
          <img
            src="assets/images/productDetailsImages//mamaearth natural face wash ubtan Face Wash for Repairs Sun Damage1.jpg"
            alt=""
          />
          <h1>Repairs Sun Damage*</h1>
          <p>
            Walnut Beads gently exfoliate dead skin cells to remove impurities
            while unearthing the smooth, bright skin underneath. Bid goodbye
            to sun damage with this natural Ubtan Face Wash. <br /> *Based on
            the natural properties of the key ingredient
          </p>
        </div>
        <div className="resultimg">
          <img
            src="assets/images/productDetailsImages//mamaearth ubtan face wash with turmeric _ saffron for Brightens Skin1.jpg"
            alt=""
          />
          <h1>Brightens Skin</h1>
          <p>
            Turmeric, rich in antioxidants, protects the skin cells from free
            radical damage and brightens the skin tone while improving the
            skin texture and complexion. It retains moisture balance to make
            the skin soft and supple.
          </p>
        </div>
      </div> */}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Results;
