import React, { useState } from "react";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { AiOutlineCaretRight } from "react-icons/ai";
import { BsHouseDoor, BsPencilFill } from "react-icons/bs";
import MyDashBoard from "./dashboard/MyDashBoard";
import Order from "./dashboard/Order";
import Address from "./dashboard/Address";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/athentication/Athentication";
import { clearOrder } from "../../redux/order/OrderSlice";

const DashBoard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [toggle, setToggle] = useState(true);

  const logout = () => {
    dispatch(authActions.signout());
    dispatch(clearOrder());
    // localStorage.clear();
    navigate("/");
  };

  const handlescrolltop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <section className="breadscrumb-section  pt-0">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12 ">
              <div className="breadscrumb-contain menumainhomebar">
                <h2>User Dashboard</h2>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <BsHouseDoor className="mb-1 mx-1" />
                      </Link>
                    </li>
                    <li className="active" aria-current="page">
                      <span>
                        <AiOutlineCaretRight />
                      </span>
                      User Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="user-dashboard-section section-b-space">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-xxl-3 col-lg-4">
              <div className="dashboard-left-sidebar">
                <div className="close-button d-flex d-lg-none">
                  <button className="close-sidebar">
                    <i className="fa-solid fa-xmark" />
                  </button>
                </div>
                <div className="profile-box">
                  <div className="cover-image">
                    <img
                      src="../assets/images/inner-page/cover-img.jpg"
                      className="img-fluid blur-up lazyload"
                      alt="img"
                    />
                  </div>
                  <div className="profile-contain">
                    <div className="profile-image">
                      <div className="row" style={{ justifyContent: "center" }}>
                        <img
                          src="../assets/images/logo/logo192.png"
                          className="blur-up lazyload update_img text-center"
                          alt="img"
                        />
                        {/* <div className="cover-icon">
                          <input
                            type="file"
                            id="actual-btn"
                            hidden
                           
                          />
                          <label htmlFor="actual-btn">
                            <BsPencilFill className="fa-solid fa-pen " />
                          </label>
                        </div> */}
                      </div>
                    </div>
                    <div className="profile-name">
                      <h6 className="text-content"></h6>
                    </div>
                  </div>
                </div>
                <ul
                  className="nav nav-pills user-nav-pills"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-dashboard-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-dashboard"
                      type="button"
                      role="tab"
                      aria-controls="pills-dashboard"
                      aria-selected="true"
                    >
                      <i data-feather="home" />
                      DashBoard
                    </button>
                  </li>
                  {/* 
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-order-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-order"
                      type="button"
                      role="tab"
                      aria-controls="pills-order"
                      aria-selected="false"
                    >
                      <i data-feather="shopping-bag" />
                      Order
                    </button>
                  </li> */}

                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-address-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-address"
                      type="button"
                      role="tab"
                      aria-controls="pills-address"
                      aria-selected="false"
                    >
                      <i data-feather="map-pin" />
                      Address
                    </button>
                  </li>

                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={(e) => logout(e)}
                  >
                    <button
                      className="nav-link"
                      data-bs-toggle="pill"
                      type="button"
                      role="tab"
                      aria-selected="false"
                    >
                      <i data-feather="shield" />
                      Log Out
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xxl-9 col-lg-8">
              {/* <button
                className="btn left-dashboard-show btn-animation btn-md fw-bold d-block mb-4 d-lg-none"
                onClick={() => setToggle(!toggle)}
              >
                Show Menu
              </button> */}
              {toggle ? (
                <>
                  <div className="mobileviewds mb-4">
                    <ul
                      className="nav nav-pills user-nav-pills"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active formobile"
                          id="pills-dashboard-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-dashboard"
                          type="button"
                          role="tab"
                          aria-controls="pills-dashboard"
                          aria-selected="true"
                        >
                          <i data-feather="home" />
                          DashBoard
                        </button>
                      </li>

                      {/* <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-order-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-order"
                          type="button"
                          role="tab"
                          aria-controls="pills-order"
                          aria-selected="false"
                        >
                          <i data-feather="shopping-bag" />
                          Order
                        </button>
                      </li> */}

                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-address-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-address"
                          type="button"
                          role="tab"
                          aria-controls="pills-address"
                          aria-selected="false"
                        >
                          <i data-feather="map-pin" />
                          Address
                        </button>
                      </li>

                      <li
                        className="nav-item"
                        role="presentation"
                        onClick={(e) => logout(e)}
                      >
                        <button
                          className="nav-link"
                          data-bs-toggle="pill"
                          type="button"
                          role="tab"
                          aria-selected="false"
                        >
                          <i data-feather="shield" />
                          Log Out
                        </button>
                      </li>
                    </ul>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="dashboard-right-sidebar">
                <div className="tab-content" id="pills-tabContent">
                  <MyDashBoard />
                  {/* <Order /> */}
                  <Address />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="theme-option">
        <div className="back-to-top" onClick={handlescrolltop}>
          <a id="back-to-top">
            <i className="fas fa-chevron-up" />
          </a>
        </div>
      </div>
      {/* Bg overlay Start */}
      <div className="bg-overlay" />
      {/* Bg overlay End */}
    </>
  );
};

export default DashBoard;
