import React from "react";

const Shipping = () => {
    return (
        <>
            <section className="container  ">
                <h3 className=" text-center return">Shipping Policy</h3>
                <hr></hr>
                <div className="">
                    <h2 className=" ">Shipping Policy for bekhup.com </h2>
                    <p className="mt-lg-5">
                        At Bekhup <b>(Branch of Moon Spagyric Pharma)</b>, accessible from www.bekhup.com, one of our main
                        priorities is the privacy of our visitors. This Shipping Policy
                        document contains types of information that is collected and
                        recorded by Bekhup and how we use it.
                    </p>
                    <p>
                        If you have additional questions or require more information about
                        our Shipping Policy, do not hesitate to contact us.
                    </p>
                    <h2 className="mt-lg-5">How does the delivery process work?</h2>
                    <p className="mt-lg-4">
                        Once our system processes your order, your products are inspected thoroughly to ensure they are in perfect condition.
                        After they pass through the final round of quality check, they are packed and handed over to our trusted delivery partner.
                        Our delivery partners then bring the package to you at the earliest possibility. In case, they are unable to reach your provided address or at a suitable time, they will contact you to resolve the issue.
                    </p>
                    {/* <h2 className="mt-lg-5">What is the range of locations to which Toners Touch ships their products?</h2> */}
                    {/* <p className="mt-lg-4">
                        Bekh-hub ships throughout India
                    </p> */}
                    <h2 className="mt-lg-5">My order has been shipped. Now how can I track it?</h2>
                    <p className="mt-lg-4">

                        Once your order has been dispatched, you will receive a photocopy of the tracking number through WhatsApp or an email with the details of the tracking number and the courier company that is processing your order.
                    </p>
                    <p className="mt-lg-4">
                        You can track the status of your package 24 hours after your order is dispatched from our warehouse.
                    </p>
                    <h2 className="mt-lg-4">What is the estimated delivery time?</h2>
                    <p className="mt-lg-4">

                        We usually dispatch most of the orders within 1-3 working days (excluding Sundays and public holidays)
                    </p>
                    <p>
                        Though we keep 95% of our catalogue in our inventory, certain products need to be sourced by ourselves so that we will be providing fresh & non-expired products.
                    </p>
                    <p>
                        Our products will be delivered in and around Hyderabad (Telangana State) from 1 to 5 working days for other states, it will be delivered within 10 working days (excluding Sundays and public holidays) from the date of dispatch.
                    </p>

                    <h2 className="mt-lg-5">Are there any shipping charges applicable on my order?</h2>
                    <p className="mt-lg-4">
                        Yes. We have shipping charges for our products according to the applicable areas and product weight.
                    </p>
                </div>
            </section>
            <div className="mt-lg-5"></div>
        </>
    );
};

export default Shipping;
