import React from "react";

const HowtoUse = ({ useProcedure }) => {
  return (
    <div className="howtousecontainer">
      <ul >
        {useProcedure.length > 0 &&
          useProcedure.map((indPro, index) => (
            <div style={{width:"50%"}}>
                  <b>Step {index} :</b> {indPro?.steps_of_use}
                 
            </div>
          ))}
      </ul>
    </div>
  );
};

export default HowtoUse;
