import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  VegBannerTotal: localStorage.getItem("VegBannerTotal")
    ? JSON.parse(localStorage.getItem("VegBannerTotal"))
    : [],
  VegSupCatBanner: localStorage.getItem("VegBannerTotal")
    ? JSON.parse(localStorage.getItem("VegBannerTotal")).filter(
        (ban) => ban.superCategory === "Vegetables & fruits"
      )
    : [],
  nonVegSupCatBanner: localStorage.getItem("VegBannerTotal")
    ? JSON.parse(localStorage.getItem("VegBannerTotal")).filter(
        (ban) => ban.superCategory === "Non-veg"
      )
    : [],
  supermartSupCatBanner: localStorage.getItem("VegBannerTotal")
    ? JSON.parse(localStorage.getItem("VegBannerTotal")).filter(
        (ban) => ban.superCategory === "Supermart"
      )
    : [],

  VegSubcatBannerTotal: localStorage.getItem("VegSubcatBannerTotal")
    ? JSON.parse(localStorage.getItem("VegSubcatBannerTotal"))
    : [],
  displayVegSubcatBannerTotal: localStorage.getItem("VegSubcatBannerTotal")
    ? JSON.parse(localStorage.getItem("VegSubcatBannerTotal")).filter(
        (ban) => ban.superCategory === "Vegetables & fruits"
      )
    : [],
  NonvegSubcatBannerTotal: localStorage.getItem("VegSubcatBannerTotal")
    ? JSON.parse(localStorage.getItem("VegSubcatBannerTotal")).filter(
        (ban) => ban.superCategory === "Non-veg"
      )
    : [],
  SupermartSubcatBannerTotal: localStorage.getItem("VegSubcatBannerTotal")
    ? JSON.parse(localStorage.getItem("VegSubcatBannerTotal")).filter(
        (ban) => ban.superCategory === "Supermart"
      )
    : [],

  isBannerLoading: false,
  isSubCatSmallBannersLoading: false,
  updateVegBanner: false,
};

export const getSuperCatBanners = createAsyncThunk(
  "HomeBanner/getSuperCatBanners",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/slider/all`;
      const resp = await axios.get(url);
      return resp.data.sliders;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);
export const getSubCatSmallBanners = createAsyncThunk(
  "HomeBanner/getSubCatSmallBanners",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/subcatbanner/all`;
      const resp = await axios.get(url);
      return resp.data.subcategorybanners;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

const HomeBannerSlice = createSlice({
  name: "HomeBanner",
  initialState,
  reducers: {
    VegBannerset(state, action) {},
    VegSubcatBannerset(state, action) {},
  },
  extraReducers: {
    [getSuperCatBanners.pending]: (state) => {
      state.isBannerLoading = true;
    },
    [getSuperCatBanners.fulfilled]: (state, action) => {
      state.VegBannerTotal = action.payload.slice().reverse();
      state.VegSupCatBanner = state.VegBannerTotal.filter(
        (ban) => ban.superCategory === "Vegetables & fruits"
      );
      state.nonVegSupCatBanner = state.VegBannerTotal.filter(
        (ban) => ban.superCategory === "Non-veg"
      );
      state.supermartSupCatBanner = state.VegBannerTotal.filter(
        (ban) => ban.superCategory === "Supermart"
      );
      state.updateVegBanner = true;

      localStorage.setItem(
        "VegBannerTotal",
        JSON.stringify(state.VegBannerTotal)
      );

      state.isBannerLoading = false;
    },
    [getSuperCatBanners.rejected]: (state, action) => {
      state.isBannerLoading = true;
    },
    [getSubCatSmallBanners.pending]: (state) => {
      state.isSubCatSmallBannersLoading = true;
    },
    [getSubCatSmallBanners.fulfilled]: (state, action) => {
      state.VegSubcatBannerTotal = action.payload;
      state.displayVegSubcatBannerTotal = state.VegSubcatBannerTotal.filter(
        (ban) => ban.superCategory === "Vegetables & fruits"
      );
      state.NonvegSubcatBannerTotal = state.VegSubcatBannerTotal.filter(
        (ban) => ban.superCategory === "Non-veg"
      );
      state.SupermartSubcatBannerTotal = state.VegSubcatBannerTotal.filter(
        (ban) => ban.superCategory === "Supermart"
      );

      localStorage.setItem(
        "VegSubcatBannerTotal",
        JSON.stringify(state.VegSubcatBannerTotal)
      );

      state.isSubCatSmallBannersLoading = false;
    },
    [getSubCatSmallBanners.rejected]: (state, action) => {
      state.isSubCatSmallBannersLoading = true;
    },
  },
});

export const { VegBannerset, VegSubcatBannerset } = HomeBannerSlice.actions;
export default HomeBannerSlice.reducer;
