import React, { useEffect, useState } from "react";
import { FiMinus } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { IoIosArrowForward } from "react-icons/io";
import { BiSolidOffer } from "react-icons/bi";
import { FaLock } from "react-icons/fa6";
import { FaMoneyBillWave } from "react-icons/fa";
import { FaRegCommentDots } from "react-icons/fa";
import { TbBrandPaypal } from "react-icons/tb";
import { IoIosPricetags } from "react-icons/io";

import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  addtoCart,
  decrementCart,
  getCartTotal,
  removefromCart,
} from "../../redux/cart/CartSlice";
import { MdDeleteOutline } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";

// Import Swiper styles
import "swiper/css";
import { Baseurl3 } from "../../config/BaseUrl";
import axios from "axios";

// import "./styles.css";

const CartSlider = () => {
    const [hotProducts, setHotProducts] = useState([]);
    const { CartItems, cartTotalAmount, ProductTotal, ProductLoading } =
      useSelector((state) => state.Cart);
  
    const {
      address,
      currentAddress,
      addressmodel,
      loginData,
      clientWalletAmount,
    } = useSelector((state) => state.Athentication);
  
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    useEffect(() => {
      dispatch(getCartTotal());
    }, [CartItems, dispatch]);
  
    const removefromcart = (cartItem) => {
      dispatch(removefromCart(cartItem));
      dispatch(getCartTotal());
    };
  
    const handledecreasequtcart = (cartItem) => {
      dispatch(decrementCart(cartItem));
      dispatch(getCartTotal());
    };
  
    const handleincreasequtcart = (cartItem) => {
      dispatch(addtoCart(cartItem));
      dispatch(getCartTotal());
    };
    useEffect(() => {
        try {
          const fetchData = async () => {
            const url = `${Baseurl3}/api/v1/grocery/offerproducts`;
            const fetchproducts = await axios.get(url);
    
            try {
              if (fetchproducts.data.success === true) {
                const prod = fetchproducts.data.grocery;
                let fetchQntyproducts = prod.map((product) => {
                  let cartPackquentity = [];
                  let packqnty = {};
    
                  for (let index = 0; index < product.PackSizes.length; index++) {
                    const element = product.PackSizes[index];
                    packqnty = {
                      packId: element._id,
                      CartQuantity: 0,
                      Pri: element.Pri,
                    };
                    cartPackquentity = [...cartPackquentity, packqnty];
                    cartPackquentity = [...cartPackquentity];
                  }
                  product = { ...product, cartPackquentity };
                  return product;
                });
                let hotproducts = fetchQntyproducts;
    
                CartItems.forEach((cartitem) => {
                  let itemIndex = -1;
                  itemIndex = hotproducts.findIndex(
                    (product) => String(product._id) === String(cartitem.ProductId)
                  );
                  if (itemIndex >= 0) {
                    let packIndex = -1;
    
                    packIndex = hotproducts[itemIndex].cartPackquentity.findIndex(
                      (pack) => String(pack.packId) === String(cartitem.packsizeId)
                    );
    
                    hotproducts[itemIndex].cartPackquentity[
                      packIndex
                    ].CartQuantity = 1;
                    if (packIndex >= 0) {
                      hotproducts[itemIndex].cartPackquentity[
                        packIndex
                      ].CartQuantity = 1;
                    }
                  }
                });
    
                setHotProducts(hotproducts);
              }
            } catch (error) {}
          };
    
          fetchData();
        } catch (error) {}
      }, [ProductLoading]);
  return (
    <>
    <div
      style={{
        backgroundColor: "#fff",
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
        padding: "15px",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <BiSolidOffer size={20} color="#0e947a" />
            <h5 style={{ paddingLeft: "6px", fontWeight: "600" }}>
              Unlock new offers
            </h5>
          </div>

          <p style={{ fontWeight: "600" }}>0/4 Unlocked</p>
        </div>
        <Swiper className="mySwiper">
          {CartItems &&
            CartItems.map((cart, index) => (
              <SwiperSlide>
                <div className="swipermaincontainer">
                  <div className="swipersubcontainer">
                    <IoIosPricetags size={20} color="#0e947a" />
                    <h6
                      style={{
                        paddingLeft: "5px",
                        fontWeight: "600",
                        color: "#0e947a",
                      }}
                    >
                      Yey! Get 25% off on this product
                    </h6>
                  </div>
                  <div className="swipersubcontainer4">
                    <div className="swipersubcontainer2">
                      <div>
                        <img
                          src={cart.ImgUrl}
                          alt="image"
                          height="40px"
                          width="40px"
                        />
                      </div>
                      <div className="swipersubcontainer3">
                        <div>
                          <h6 style={{ fontWeight: "600" }}>
                            {cart.ItemName}
                          </h6>
                          <span>{cart.PackSize}</span>
                        </div>
                      </div>
                    </div>
                    <div className="swipersubcontainer2">
                      <div className="sliderbtn">
                        <p
                          style={{
                            color: "#fff",
                            fontWeight: "600",
                            paddingTop: "13px",
                          }}
                        >
                          Add
                        </p>
                      </div>
                      <div style={{ width: "40px" }}>
                        <div className="sliderlastbtn">
                          <LiaRupeeSignSolid />
                          <span>{cart.Price}</span>
                        </div>
                        <div className="sliderlastbtn1">
                          <LiaRupeeSignSolid />
                          <span
                            style={{
                              textDecorationLine: "line-through",
                              color: "#ff3269",
                            }}
                          >
                            {cart.Mrp}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          {CartItems &&
            CartItems.map((cart, index) => (
              <SwiperSlide>
                <div className="swipermaincontainer">
                  <div className="swipersubcontainer">
                    <IoIosPricetags size={20} color="#0e947a" />
                    <h6
                      style={{
                        paddingLeft: "5px",
                        fontWeight: "600",
                        color: "#0e947a",
                      }}
                    >
                      Yey! Get 25% off on this product
                    </h6>
                  </div>
                  <div className="swipersubcontainer4">
                    <div className="swipersubcontainer2">
                      <div>
                        <img
                          src={cart.ImgUrl}
                          alt="image"
                          height="40px"
                          width="40px"
                        />
                      </div>
                      <div className="swipersubcontainer3">
                        <div>
                          <h6 style={{ fontWeight: "600" }}>
                            {cart.ItemName}
                          </h6>
                          <span>{cart.PackSize}</span>
                        </div>
                      </div>
                    </div>
                    <div className="swipersubcontainer2">
                      <div className="sliderbtn">
                        <p
                          style={{
                            color: "#fff",
                            fontWeight: "600",
                            paddingTop: "13px",
                          }}
                        >
                          Add
                        </p>
                      </div>
                      <div style={{ width: "40px" }}>
                        <div className="sliderlastbtn">
                          <LiaRupeeSignSolid />
                          <span>{cart.Price}</span>
                        </div>
                        <div className="sliderlastbtn1">
                          <LiaRupeeSignSolid color='#808080'/>
                          <span
                            style={{ textDecorationLine: "line-through",color:'#808080' }}
                          >
                            {cart.Mrp}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          {CartItems &&
            CartItems.map((cart, index) => (
              <SwiperSlide>
                <div className="swipermaincontainer">
                    <div className="swipersubcontainer" >
                      <IoIosPricetags size={20} color="#0e947a" />
                      <h6
                        style={{
                          paddingLeft: "5px",
                          fontWeight: "600",
                          color: "#0e947a",
                        }}
                      >
                        Yey! Get 25% off on this product
                      </h6>
                    </div>
                  <div className="swipersubcontainer4">
                    <div className="swipersubcontainer2">
                      <div>
                        <img
                          src={cart.ImgUrl}
                          alt="image"
                          height="40px"
                          width="40px"
                        />
                      </div>
                      <div className="swipersubcontainer3">
                        <div>
                          <h6 style={{ fontWeight: "600" }}>
                            {cart.ItemName}
                          </h6>
                          <span>{cart.PackSize}</span>
                        </div>
                      </div>
                    </div>
                    <div className="swipersubcontainer2">
                      <div className="sliderbtn">
                        <p
                          style={{
                            color: "#fff",
                            fontWeight: "600",
                            paddingTop: "13px",
                          }}
                        >
                          Add
                        </p>
                      </div>
                      <div style={{ width: "40px" }}>
                        <div className="sliderlastbtn">
                          <LiaRupeeSignSolid />
                          <span>{cart.Price}</span>
                        </div>
                        <div className="sliderlastbtn1">
                          <LiaRupeeSignSolid />
                          <span
                            style={{ textDecorationLine: "line-through" }}
                          >
                            {cart.Mrp}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  </>
  )
}

export default CartSlider