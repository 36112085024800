import React from "react";

const Benefits = ({ header, url }) => {
  return (
    <>
      {url ? (
        <div className="BenifitsContainer">
          <h2>{header}</h2>
          <p>Removes Tan | Brightens Skin</p>
          <video src={url} controls></video>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Benefits;
