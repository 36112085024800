import React from "react";

const IngedientsListTable = ({ listIngredient }) => {
  return (
    <>
      <div>
        <table
          class="table table-bordered table-styles"
          style={{ borderColor: "skyblue", textAlign: "center" }}
        >
          <thead>
            <tr>
              <th scope="col" style={{ backgroundColor: "rgb(0, 175, 239" }}>
                Ingredients
              </th>
              <th scope="col" style={{ backgroundColor: "rgb(0, 175, 239" }}>
                Type
              </th>
              <th scope="col" style={{ backgroundColor: "rgb(0, 175, 239" }}>
                Where Is It From?
              </th>
              <th scope="col" style={{ backgroundColor: "rgb(0, 175, 239" }}>
                How It Helps?
              </th>
            </tr>
          </thead>
          <tbody>
            {listIngredient.length > 0 &&
              listIngredient.map((indPro, index) => (
                <tr key={index}>
                  <td scope="row">{indPro.ingredients}</td>
                  <td>{indPro.type}</td>
                  <td>{indPro.where_is_it_from}</td>
                  <td>{indPro.how_it_helps}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default IngedientsListTable;
