import React from "react";
import "./Disclaimer.css";
const Disclaimer = ({ disclaimer }) => {
  return (
    <>
      {disclaimer.length > 0 ? (
        <>
          <div className="mainPage">
            <div className="subPage">
              <h1 className="Heading">DISCLAIMER</h1>
              {/* <div className="borderBlue"></div> */}
              <div className="borderGrey"></div>
              <div className="list">
                <ol>
                  {disclaimer.map((indDis) => (
                    <li>{indDis?.steps_of_disclaimer}</li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Disclaimer;
