import React, { useEffect, useState } from "react";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
// import Loader from "react-loader-spinner";
import BeatLoader from "react-spinners/BeatLoader";
import { FaStar } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";
import ReactImageMagnify from "react-image-magnify";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Baseurl, Baseurl3 } from "../config/BaseUrl";
import axios from "axios";
import { FaShoppingCart } from "react-icons/fa";
import {
  addtoCart,
  decrementCart,
  primaeryChange,
} from "../redux/cart/CartSlice";
import Description from "./Home/Hotproductdetails/Description";
import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from "react-image-magnifiers";
import zIndex from "@mui/material/styles/zIndex";
import Benefits from "./ProductData/Benefits/Benefits";
import Results from "./ProductData/results/Results";
import Details from "./ProductData/results/Details";
import Faqs1 from "./ProductData/faqs/Faqs1";
import Disclaimer from "./ProductData/disclaimer/Disclaimer";
import AdditionalInfo from "./ProductData/additionalInfo/AdditionalInfo";
import ProductDescription from "./ProductData/results/ProductDescription";
const ProductDetails1 = () => {
  const { ProductTotal, CartItems, Cartloading, ProductLoading } = useSelector(
    (state) => state.Cart
  );

  const params = useParams();
  const dispatch = useDispatch();

  const [showButton, setShowButton] = useState(false);
  const [productinfo, setProductinfo] = useState([]);
  const [subCatproduct, setSubProduct] = useState([]);
  const [product, setProduct] = useState("");
  const [loading, setLoading] = useState(true);
  const [priceInfo, setPriceInfo] = useState("");
  const [selectedPackIndex, setSelectedpackIndex] = useState("0");
  const [showImageIndex, setShowImageIndex] = useState(0)
  useEffect(() => {
    try {
      const productslugurl = params.producturl;
      const fetchData = async () => {
        const url = `${Baseurl3}/api/v1/grocery/Grocerybyslugurl/${productslugurl}`;
        const currentProduct = await axios.get(url);
        try {
          if (currentProduct.data.success === true) {
            setLoading(false);
            let productInfo = currentProduct.data.grocery;
            let fetchQntyproducts = productInfo.map((product) => {
              let cartPackquentity = [];
              let packqnty = {};

              for (let index = 0; index < product.PackSizes.length; index++) {
                const element = product.PackSizes[index];
                packqnty = {
                  packId: element._id,
                  CartQuantity: 0,
                  Pri: element.Pri,
                };
                cartPackquentity = [...cartPackquentity, packqnty];
                cartPackquentity = [...cartPackquentity];
              }
              product = { ...product, cartPackquentity };
              return product;
            });

            CartItems.forEach((cartitem) => {
              let itemIndex = -1;
              itemIndex = fetchQntyproducts.findIndex(
                (product) => String(product._id) === String(cartitem.ProductId)
              );

              if (itemIndex >= 0) {
                let packIndex = -1;

                packIndex = fetchQntyproducts[
                  itemIndex
                ].cartPackquentity.findIndex(
                  (pack) => String(pack.packId) === String(cartitem.packsizeId)
                );

                fetchQntyproducts[itemIndex].cartPackquentity[
                  packIndex
                ].CartQuantity = 1;
                if (packIndex >= 0) {
                  fetchQntyproducts[itemIndex].cartPackquentity[
                    packIndex
                  ].CartQuantity = 1;
                }
              }
            });
            console.log(fetchQntyproducts, 'fetchQntyproducts');
            setProductinfo(fetchQntyproducts);
            setPriceInfo(fetchQntyproducts[0].PackSizes[0]);
          }
        } catch (error) { }
      };

      fetchData();

      if (!ProductLoading) {
        const currentProd = ProductTotal.find(
          (product) => product.Url === productslugurl
        );

        if (currentProd !== "") {
          try {
            const currentProduct = ProductTotal.filter(
              (product) => product.Url === productslugurl
            );

            const curSubCatProd = ProductTotal.filter(
              (product) => product.SubCatId === currentProd.SubCatId
            );

            const singlePro = curSubCatProd.filter(
              (product) => product.Single === true
            );
            const offerPro = singlePro.filter(
              (product) => product.Trending === true
            );
            setSubProduct(offerPro);
          } catch (error) { }
        }
      }
    } catch (error) { }
  }, [params.producturl, ProductLoading]);
  const addtocartPress = async (e, product, itemIndex, packIndex) => {
    try {
      let products = [...productinfo];
      products[itemIndex].cartPackquentity[packIndex].CartQuantity =
        products[itemIndex].cartPackquentity[packIndex].CartQuantity + 1;
      setProductinfo([...products]);
    } catch (error) { }

    const formData = {
      ProductId: product._id,
      ProductName: product.ItemName,
      CatName: product.Category,
      CatId: product.CatId,
      SubCat: product.SubCat,
      SubCatId: product.SubCatId,
      Brand: product.Brand,
      ItemName: product.ItemName,
      PackSize: product.PackSizes[packIndex].PackSize,
      Description: product.Description,
      ImgUrl: product.PackSizes[packIndex].ImgUrlMbl,
      Price: product.PackSizes[packIndex].SellingPrice,
      Qty: 1,
      TotalAmount: product.PackSizes[packIndex].SellingPrice * 1,
      Mrp: product.PackSizes[packIndex].Mrp,
      TotalPrice: product.PackSizes[packIndex].SellingPrice * 1,
      TotalMrp: product.PackSizes[packIndex].Mrp * 1,
      Discount:
        product.PackSizes[packIndex].Mrp * 1 -
        product.PackSizes[packIndex].SellingPrice * 1,
      Cashback: product.Cashback,
      SellingPrice: product.PackSizes[packIndex].SellingPrice,
      GstSellPrc: product.PackSizes[packIndex].GstSellPrc,
      CostPrc: product.PackSizes[packIndex].CostPrc,
      GstCost: product.PackSizes[packIndex].GstCost,
      packsizeId: product.PackSizes[packIndex]._id,
      maximumQuantity: product.PackSizes[packIndex].maximumQuantity,
    };
    dispatch(addtoCart(formData));
  };

  const decrementtocartPress = async (e, product, itemIndex, packIndex) => {
    try {
      let products = [...productinfo];
      products[itemIndex].cartPackquentity[packIndex].CartQuantity =
        products[itemIndex].cartPackquentity[packIndex].CartQuantity - 1;
      setProductinfo([...products]);
    } catch (error) { }

    const formData = {
      ProductId: product._id,
      ProductName: product.ItemName,
      CatName: product.Category,
      CatId: product.CatId,
      SubCat: product.SubCat,
      SubCatId: product.SubCatId,
      Brand: product.Brand,
      ItemName: product.ItemName,
      PackSize: product.PackSizes[packIndex].PackSize,
      Description: product.Description,
      ImgUrl: product.PackSizes[packIndex].ImgUrlMbl,
      Price: product.PackSizes[packIndex].SellingPrice,
      Qty: 1,
      TotalAmount: product.PackSizes[packIndex].SellingPrice * 1,
      Mrp: product.PackSizes[packIndex].Mrp,
      TotalPrice: product.PackSizes[packIndex].SellingPrice * 1,
      TotalMrp: product.PackSizes[packIndex].Mrp * 1,
      Discount:
        product.PackSizes[packIndex].Mrp * 1 -
        product.PackSizes[packIndex].SellingPrice * 1,
      Cashback: product.Cashback,
      SellingPrice: product.PackSizes[packIndex].SellingPrice,
      GstSellPrc: product.PackSizes[packIndex].GstSellPrc,
      CostPrc: product.PackSizes[packIndex].CostPrc,
      GstCost: product.PackSizes[packIndex].GstCost,
      packsizeId: product.PackSizes[packIndex]._id,
      maximumQuantity: product.PackSizes[packIndex].maximumQuantity,
    };
    dispatch(decrementCart(formData));
  };

  const changePrimery = (productId, packId, proindex) => {
    let product = [...productinfo];
    product[proindex].PackSizes = product[proindex].PackSizes.map((pack) => {
      if (pack._id === packId) {
        pack.Pri = true;
      } else {
        pack.Pri = false;
      }

      return pack;
    });

    setProductinfo([...product]);
  };
  // visible hidden
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      // Adjust this threshold to your desired scroll position
      const threshold = 1200;
      if (window.scrollY > threshold) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  console.log(productinfo, "productinfo");
  return (
    <>
      <section className="product-section w-100">
        <div className="container-fluid-lg w-100">
          {productinfo.map((data, proindex) => (
            <>
              <div key={proindex}>
                {data.PackSizes.map((pack, imgpackIndex) =>
                  pack.Pri === true ? (
                    <>
                      <div className="row" key={imgpackIndex}>
                        <div className="col-xxl-8 col-xl-8 col-lg-7 wow fadeInUp">
                          <div className="row g-5 m-4">
                            <div className="col-xl-6 col-lg-6 wow fadeInUp">
                              <div className="product-left-box">
                                <div className="row g-2">
                                  <div className="col-xxl-10 col-lg-12 col-md-6">
                                    <div className="product-main-2 no-arrow">
                                      <div className="col-lg-2 col-4"></div>
                                      <div style={{ display: "flex" }}>
                                        <div>
                                          {pack.ImgUrlDesk?.map(
                                            (image, index) => (
                                              <img
                                                onClick={() => setShowImageIndex(index)}
                                                src={image.ImgUrlDeskShow}
                                                alt="product-img"
                                                style={{
                                                  maxHeight: "110px",
                                                  minHeight: "110px",
                                                  marginTop: "10px",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            )
                                          )}
                                        </div>
                                        <div style={{ marginLeft: "15px" }}>
                                          <div
                                            className="slider-image"
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              width: "100%",
                                              border: ".5px solid #ccc",
                                              borderRadius: "5px",
                                            }}
                                          >
                                            {pack.hasOwnProperty(
                                              "ImgUrlDesk"
                                            ) ? (
                                              <div>
                                                <ReactImageMagnify
                                                  {...{
                                                    smallImage: {
                                                      alt: "Wristwatch by Ted Baker London",
                                                      isFluidWidth: true,
                                                      src: pack.ImgUrlDesk[showImageIndex]
                                                        ?.ImgUrlDeskShow,
                                                      isFluidWidth: true,
                                                    },
                                                    largeImage: {
                                                      className: "large",
                                                      isFluidWidth: true,
                                                      src: pack.ImgUrlDesk[showImageIndex]
                                                        ?.ImgUrlDeskShow,
                                                      width: 1200,
                                                      height: 1800,
                                                    },

                                                    enlargedImageContainerDimensions:
                                                    {
                                                      width: "130%", // Adjust according to your need
                                                      height: "115%", // Adjust according to your need
                                                    },
                                                  }}
                                                />
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-xl-6 col-lg-6 wow fadeInUp g-5 z-3"
                              data-wow-delay="0.1s"
                            >
                              <div className="d-flex justify-content-center ">
                                <div className="right-box-contain ">
                                  <h4 className="name p">{data.ItemName}</h4>
                                  <div>
                                    <p style={{ fontSize: "15PX" }}>
                                      {data.Title}
                                    </p>
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <div style={{ display: "flex" }}>
                                      <p
                                        style={{
                                          fontSize: "15px",
                                          color: "#000",
                                        }}
                                      >
                                        {data.Rating}
                                      </p>
                                      <FaStar
                                        style={{
                                          color: "gold",
                                          paddingLeft: "5px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <h3
                                      style={{
                                        fontSize: "12px",
                                        color: "#59a30e",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Special Price
                                    </h3>
                                  </div>
                                  {priceInfo && (
                                    <div className="price-rating mt-1">
                                      <h3
                                        className="price"
                                        style={{
                                          fontSize: "16px",
                                          color: "#000",
                                        }}
                                      >
                                        ₹ {priceInfo?.SellingPrice}
                                        <del
                                          className="text-content"
                                          style={{
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                            fontSize: "13px",
                                            fontWeight: "600",
                                          }}
                                        >
                                          ₹ {priceInfo?.Mrp}
                                        </del>
                                        <span className="offer theme-color">
                                          {priceInfo?.Discount}% off
                                        </span>
                                        <span
                                          style={{
                                            color: "#555",
                                            fontStyle: "italic",
                                            fontSize: "10px",
                                            paddingLeft: "6px",
                                          }}
                                        >
                                          incl. of all taxes
                                        </span>
                                      </h3>
                                    </div>
                                  )}
                                  <div className="procuct-contain">
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        color: "#666",
                                      }}
                                    >
                                      Select Variant
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",

                                      width: "100%",
                                      gap: "20px",
                                    }}
                                    id="pro"
                                  >
                                    {data.PackSizes.map((pacdata, index) => (
                                      <div
                                        style={{
                                          border: `${priceInfo._id === pacdata._id
                                              ? ".5px solid green"
                                              : ".5px solid #555"
                                            }`,
                                          borderRadius: "8px",
                                          width: "auto",
                                          overflow: "hidden",
                                        }}
                                        onClick={() => {
                                          setPriceInfo(pacdata);
                                          setSelectedpackIndex(index);
                                        }}
                                      >
                                        <div
                                          style={{
                                            borderRadius: "8px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <p
                                            style={{
                                              borderBottom: ".6px solid #666",
                                              paddingBottom: "5px",
                                              width: "100%",
                                              paddingBottom: "12px",
                                              padding: "10px",
                                              backgroundColor: `${priceInfo._id === pacdata._id
                                                  ? "green"
                                                  : "#f4f5f7"
                                                }`,
                                              color: `${priceInfo._id === pacdata._id
                                                  ? "#fff"
                                                  : "#555"
                                                }`,
                                            }}
                                          >
                                            {pacdata.PackSize}
                                          </p>
                                        </div>
                                        <div>
                                          <h3
                                            className=""
                                            style={{
                                              fontSize: "16px",
                                              color: "#000",
                                              marginTop: "-10px",
                                              paddingLeft: "5px",
                                            }}
                                          >
                                            ₹ {pacdata?.SellingPrice}
                                            <del
                                              className="text-content"
                                              style={{
                                                paddingLeft: "10px",
                                                paddingRight: "10px",
                                                fontSize: "13px",
                                                fontWeight: "600",
                                              }}
                                            >
                                              ₹ {pacdata?.Mrp}
                                            </del>
                                            <span
                                              className="offer theme-color"
                                              style={{ fontSize: "12px" }}
                                            >
                                              {pacdata?.Discount}% off
                                            </span>
                                          </h3>
                                        </div>
                                        <div style={{ marginBottom: "7px" }}>
                                          <h3
                                            className=""
                                            style={{
                                              fontSize: "16px",
                                              color: "#59a30e",
                                              marginTop: "8px",
                                              paddingLeft: "5px",
                                            }}
                                          >
                                            ₹ {pacdata?.SellingPrice} /{" "}
                                            {pacdata?.PackSize}
                                          </h3>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <div style={{ marginTop: "10px" }}>
                                    <img
                                      src="/assets/images/earth.png"
                                      style={{ width: "300px" }}
                                    />
                                  </div>
                                  <div className="note-box product-packege">
                                    {productinfo[proindex].cartPackquentity[
                                      selectedPackIndex
                                    ].CartQuantity > 0 ? (
                                      <>
                                        {" "}
                                        <div className="cart_qty qty-box product-qty">
                                          <div className="input-group">
                                            <button
                                              type="button"
                                              className="qty-left-minus"
                                              data-type="minus"
                                              data-field
                                              onClick={(e) =>
                                                decrementtocartPress(
                                                  e,
                                                  data,
                                                  proindex,
                                                  selectedPackIndex
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-minus"
                                                aria-hidden="true"
                                              />
                                            </button>
                                            <input
                                              className="form-control input-number qty-input"
                                              type="text"
                                              name="quantity"
                                              value={
                                                productinfo[proindex]
                                                  .cartPackquentity[
                                                  selectedPackIndex
                                                ].CartQuantity
                                              }
                                              contentEditable="false"
                                            />

                                            {9 >=
                                              productinfo[proindex]
                                                .cartPackquentity[
                                                selectedPackIndex
                                              ].CartQuantity ? (
                                              <>
                                                <button
                                                  type="button"
                                                  className="qty-right-plus"
                                                  data-type="plus"
                                                  data-field
                                                  onClick={(e) =>
                                                    addtocartPress(
                                                      e,
                                                      data,
                                                      proindex,
                                                      selectedPackIndex
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className="fa fa-plus"
                                                    aria-hidden="true"
                                                  />
                                                </button>
                                              </>
                                            ) : (
                                              <>
                                                <button
                                                  type="button"
                                                  className="qty-right-plus"
                                                  data-type="plus"
                                                  data-field
                                                >
                                                  <i
                                                    className="fa fa-plus"
                                                    aria-hidden="true"
                                                  />
                                                </button>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          className="btn btn-md bg-success cart-button text-white w-100"
                                          onClick={(e) =>
                                            addtocartPress(
                                              e,
                                              data,
                                              proindex,
                                              selectedPackIndex
                                            )
                                          }
                                        >
                                          Add To Cart
                                        </button>
                                      </>
                                    )}
                                  </div>
                                  {/* <div>
                                    <div className="" style={{ margin: "8px" }}>
                                      <div
                                        className="cart_qty qty-box mx-auto"
                                        style={{
                                          margin: "8px",
                                          width: "95%",
                                        }}
                                      >
                                        <div
                                          className="input-group"
                                          style={{ width: "100%" }}
                                        >
                                          <button
                                            type="button"
                                            className="qty-left-minus"
                                            data-type="minus"
                                            data-field
                                          >
                                            <i
                                              className="fa fa-minus"
                                              aria-hidden="true"
                                            />
                                          </button>
                                          <input
                                            className="form-control input-number qty-input"
                                            type="text"
                                            name="quantity"
                                            contentEditable="false"
                                            value={34}
                                          />

                                          <button
                                            type="button"
                                            className="qty-right-plus"
                                            data-type="plus"
                                            data-field
                                          >
                                            <i
                                              className="fa fa-plus"
                                              aria-hidden="true"
                                            />
                                          </button>
                                        </div>
                                      </div>
                                      <button
                                        className="btn btn-md cart-button text-white w-100"
                                        style={{
                                          backgroundColor: "#00AFEF",
                                        }}
                                      >
                                        <FaShoppingCart
                                          style={{
                                            paddingRight: "10px",
                                            fontSize: "26px",
                                          }}
                                        />{" "}
                                        Add To Cart
                                      </button>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>

                            <div className="col-12"></div>
                          </div>
                        </div>
                        <div className={isVisible ? "" : "invisible"}>
                          <div
                            className="col-xxl-4 col-xl-4 col-lg-5 d-none d-lg-block wow fadeInUp trending_fixed"
                            style={{
                              position: "fixed",
                              top: "15%",
                              left: "72%",
                            }}
                          >
                            <div className="right-sidebar-box">
                              <div className="pt-25">
                                <div className="category-menu">
                                  <h3>Trending Products</h3>
                                  <ul className="product-list product-right-sidebar border-0 p-0">
                                    {subCatproduct.map((data, index) => (
                                      <li key={index}>
                                        {data.PackSizes.map((pack) =>
                                          pack.Pri === true ? (
                                            <>
                                              <Link
                                                to={`/product-info/${data.Url}`}
                                              >
                                                <div
                                                  className="offer-product"
                                                  key={pack._id}
                                                >
                                                  <a className="offer-image">
                                                    <img
                                                      src={
                                                        pack.ImgUrlDesk[0]
                                                          .ImgUrlDeskShow
                                                      }
                                                      className="img-fluid blur-up lazyload"
                                                      alt="img"
                                                    />
                                                  </a>
                                                  <div className="offer-detail">
                                                    <div>
                                                      <a>
                                                        <h6 className="name">
                                                          {data.ItemName}
                                                        </h6>
                                                      </a>

                                                      <span>
                                                        {pack.SellingPrice}
                                                      </span>
                                                      {pack.Mrp ===
                                                        pack.SellingPrice ? (
                                                        <> </>
                                                      ) : (
                                                        <>
                                                          {" "}
                                                          <h6 className="price theme-color">
                                                            {pack.Mrp}
                                                          </h6>
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </Link>
                                            </>
                                          ) : (
                                            <></>
                                          )
                                        )}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                              {/* Banner Section */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div></div>
                    </>
                  )
                )}
              </div>
              <ProductDescription description={data?.Description} />
              <Benefits header={data?.video_header} url={data?.video} />
              <Results result={data?.result_of_product} />
              {/* <Description
                ingredient={data?.key_ingredients}
                useProcedure={data?.how_to_use}
                listIngredient={data?.list_of_ingredients}
                suitablefor={data?.suitable_for}
              /> */}
              <Details
                ingredient={data?.key_ingredients}
                useProcedure={data?.how_to_use}
                listIngredient={data?.list_of_ingredients}
                suitablefor={data?.suitable_for}
              />
              <Faqs1 />
              <Disclaimer disclaimer={data?.disclaimer_of_product} />
              <AdditionalInfo
                dimensions={data?.dimensions}
                manufacturer={data?.manufacturer}
                uselisense={data?.lisense_number}
                useNodal={data?.nodal_officer_detail}
                usefore={data?.use_before}
                useMarket={data?.marketed_by}
                useCountry={data?.country_of_origin}
              />
              {/* <div
                style={{
                  marginBottom: "20vh",
                }}
              ></div> */}
            </>
          ))}
        </div>
      </section>

      {/* <div className="sticky-bottom-cart">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="cart-content">
                <div className="product-image">
                  <img
                    src="../assets/images/product/category/1.jpg"
                    className="img-fluid blur-up lazyload"
                    alt="img"
                  />
                  <div className="content">
                    <h5>Creamy Chocolate Cake</h5>
                    <h6>
                      ₹32.96<del className="text-danger">₹96.00</del>
                      <span>55% off</span>
                    </h6>
                  </div>
                </div>
                <div className="selection-section">
                  <div className="form-group mb-0">
                    <select
                      id="input-state"
                      className="form-control form-select"
                    >
                      <option selected disabled>
                        Choose Weight...
                      </option>
                      <option>1/2 KG</option>
                      <option>1 KG</option>
                      <option>1.5 KG</option>
                    </select>
                  </div>
                  <div className="cart_qty qty-box product-qty m-0">
                    <div className="input-group h-100">
                      <button
                        type="button"
                        className="qty-left-minus"
                        data-type="minus"
                        data-field
                      >
                        <i className="fa fa-minus" aria-hidden="true" />
                      </button>
                      <input
                        className="form-control input-number qty-input"
                        type="text"
                        name="quantity"
                      />
                      <button
                        type="button"
                        className="qty-right-plus"
                        data-type="plus"
                        data-field
                      >
                        <i className="fa fa-plus" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="add-btn">
                  <a className="btn theme-bg-color text-white wishlist-btn">
                    <i className="fa fa-bookmark" /> Wishlist
                  </a>
                  <a className="btn theme-bg-color text-white">
                    <i className="fas fa-shopping-cart" /> Add To Cart
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ProductDetails1;
