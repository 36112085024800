import React from "react";

const KeyIngredients = ({ ingredient }) => {
  return (
    <>
      <div className="keyIngredientsContainer">
        {ingredient.length > 0 &&
          ingredient.map((indIngredient, key) => (
            <div className="contentContainer">
              <img src={indIngredient?.product_imgurl} alt="turmeric" />
              <p>
                <span className="product_title">
                  {indIngredient?.product_header} :
                </span>
                {indIngredient?.product_description}
              </p>
            </div>
          ))}
      </div>
    </>
  );
};

export default KeyIngredients;
