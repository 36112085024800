import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { useDispatch, useSelector } from "react-redux";
import { addtoCart, decrementCart } from "../../redux/cart/CartSlice";
import { Baseur2, Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const MonthelyEssential = () => {
  const { clientOrder } = useSelector((state) => state.Order);
  const { isAuth, clientid } = useSelector((state) => state.Athentication);
  const { CartItems, Cartloading, ProductLoading } = useSelector(
    (state) => state.Cart
  );

  const dispatch = useDispatch();

  const [monthelyessential, setMonthelyessential] = useState([]);
  const [protest, setProtest] = useState(false);
  const [orderProIndex, setOrderProIndex] = useState(0);

  useEffect(() => {
    try {
      const fetchData = async () => {
        const url = `${Baseurl}/api/v1/order/tenOrderbyclient/${clientid}`;
        const orderFetchproducts = await axios.get(url);
        try {
          if (orderFetchproducts.data.success === true) {
            const fetchorder = orderFetchproducts.data.order;
            let orderproductList = [];

            for (let index = 0; index < fetchorder.length; index++) {
              orderproductList = [
                ...orderproductList,
                ...fetchorder[index].OrderProducts,
              ];
            }
            // remove duplicate product
            let result = [];
            result = Object.values(
              orderproductList.reduce((r, o) => {
                r[o.ProductId] = o;
                return r;
              }, {})
            );

            let ordersProducts = result.map(
              (product) => (product = { ...product, CartQuantity: 0 })
            );

            CartItems.forEach((cartitem) => {
              let itemIndex = -1;
              itemIndex = ordersProducts.findIndex(
                (product) => product.ProductId === cartitem.ProductId
              );

              if (itemIndex >= 0) {
                ordersProducts.CartQuantity = cartitem.Qty;
              }
            });

            setMonthelyessential(ordersProducts);
          }
        } catch (error) {}
      };
      if (isAuth === true) {
        fetchData();
      }
    } catch (error) {}
  }, [ProductLoading]);

  const addtocartPress = async (e, order, itemIndex) => {
    try {
      let products = [...monthelyessential];

      products[itemIndex].CartQuantity = products[itemIndex].CartQuantity + 1;

      setMonthelyessential([...products]);

      setProtest(!protest);
    } catch (error) {}

    const formData = {
      ProductId: order.ProductId,
      ProductName: order.ProductName,
      CatName: order.CatName,
      CatId: order.CatId,
      SubCat: order.SubCat,
      SubCatId: order.SubCatId,
      Brand: order.Brand,
      ItemName: order.ItemName,
      PackSize: order.PackSize,
      Description: order.Description,
      ImgUrl: order.ImgUrl,
      Price: Number(order.Price),
      Qty: 1,
      TotalAmount: Number(order.Price) * 1,
      Mrp: order.Mrp,
      TotalPrice: order.Price * 1,
      TotalMrp: order.Mrp * 1,
      Discount: order.Mrp * 1 - order.Price * 1,
      Cashback: order.Cashback,
      SellingPrice: order.Price,
      GstSellPrc: order.GstSellPrc,
      CostPrc: order.CostPrc,
      GstCost: order.GstCost,
      packsizeId: order.packsizeId,
      maximumQuantity: order.maximumQuantity,
    };

    dispatch(addtoCart(formData));
  };

  const decrementtocartPress = async (e, order, itemIndex) => {
    try {
      let products = [...monthelyessential];

      products[itemIndex].CartQuantity = products[itemIndex].CartQuantity - 1;

      setMonthelyessential([...products]);
      setProtest(!protest);
    } catch (error) {}

    const formData = {
      ProductId: order.ProductId,
      ProductName: order.ProductName,
      CatName: order.CatName,
      CatId: order.CatId,
      SubCat: order.SubCat,
      SubCatId: order.SubCatId,
      Brand: order.Brand,
      ItemName: order.ItemName,
      PackSize: order.PackSize,
      Description: order.Description,
      ImgUrl: order.ImgUrl,
      Price: Number(order.Price),
      Qty: 1,
      TotalAmount: Number(order.Price) * 1,
      Mrp: order.Mrp,
      TotalPrice: order.Price * 1,
      TotalMrp: order.Mrp * 1,
      Discount: order.Mrp * 1 - order.Price * 1,
      Cashback: order.Cashback,
      SellingPrice: order.Price,
      GstSellPrc: order.GstSellPrc,
      CostPrc: order.CostPrc,
      GstCost: order.GstCost,
      packsizeId: order.packsizeId,
      maximumQuantity: order.maximumQuantity,
    };

    dispatch(decrementCart(formData));
  };

  return (
    <>
      {monthelyessential.length > 0 ? (
        <>
          <section className="product-section-3">
            <div className="container-fluid-lg">
              <div className="title">
                <h2>Your Monthly Essential !</h2>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="slider-7_1 arrow-slider img-slider">
                    <Swiper
                      draggable={true}
                      spaceBetween={10}
                      modules={[Autoplay]}
                      // loop={true}
                      // autoplay={{
                      //   delay: 1500,
                      //   disableOnInteraction: false,
                      //   pauseOnMouseEnter: true,
                      // }}
                      breakpoints={{
                        360: {
                          slidesPerView: 2,
                          spaceBetween: 5,
                        },
                        460: {
                          slidesPerView: 2,
                          spaceBetween: 5,
                        },
                        720: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                        1080: {
                          slidesPerView: 6,
                          spaceBetween: 7,
                        },
                      }}
                      className="mySwiper"
                    >
                      {monthelyessential &&
                        monthelyessential.map((order, itemIndex) => (
                          <SwiperSlide key={itemIndex}>
                            <div>
                              <div className="product-box-4 wow fadeInUp ">
                                <div className="product-image product-image-2">
                                  <a>
                                    <img
                                      src={order.ImgUrl}
                                      className="img-fluid blur-up lazyload"
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                    />
                                  </a>
                                </div>
                                <div className="product-detail">
                                  <a href="product-left-thumbnail.html">
                                    <h5 className="name text-title">
                                      {order.ItemName}
                                    </h5>
                                  </a>
                                  <h5 className="price theme-color">
                                    ₹ {order.Price}
                                    <del>₹ {order.Mrp}</del>
                                  </h5>
                                  <div className="addtocart_btn ">
                                    {order.CartQuantity > 0 ? (
                                      <>
                                        <div className="qty-box cart_qty open">
                                          <div className="input-group  ">
                                            <button
                                              type="button"
                                              className="btn qty-left-minus"
                                              data-type="minus"
                                              data-field
                                              onClick={(e) =>
                                                decrementtocartPress(
                                                  e,
                                                  order,
                                                  itemIndex
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-minus"
                                                aria-hidden="true"
                                              />
                                            </button>
                                            <input
                                              className="form-control input-number qty-input"
                                              type="text"
                                              name="quantity"
                                              defaultValue={0}
                                              value={order.CartQuantity}
                                              readonly="readonly"
                                            />
                                            <button
                                              type="button"
                                              className="btn qty-right-plus"
                                              data-type="plus"
                                              data-field
                                              onClick={(e) =>
                                                addtocartPress(
                                                  e,
                                                  order,
                                                  itemIndex
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-plus"
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          className="add-button addcart-button btn buy-button text-light"
                                          onClick={(e) =>
                                            addtocartPress(e, order, itemIndex)
                                          }
                                        >
                                          <i className="fa-solid fa-plus" />
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default MonthelyEssential;
