import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import EditProfile from "./EditProfile";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import {
  clientUpdate,
  getClientByMob,
  walletTransitionPost,
} from "../../../redux/athentication/Athentication";
import {
  gerenatenewTokrnId,
  getOrderbyClId,
  updateOrder,
} from "../../../redux/order/OrderSlice";
import { toast } from "react-toastify";
import axios from "axios";

const MyDashBoard = () => {
  const { loginData, clientid } = useSelector((store) => store.Athentication);
  const { clientOrder } = useSelector((state) => state.Order);

  const dispatch = useDispatch();

  const [model, setModel] = useState(false);

  useEffect(() => {}, []);

  const orderCancelClick = async (e, selectOrder) => {
    var date = new Date();

    date.setDate(date.getDate());
    if (selectOrder.hasOwnProperty("OrderprocessDate")) {
    } else {
      var OrderprocessDate = {};
    }

    if (selectOrder.hasOwnProperty("OrderprocessDate")) {
      var OrderprocessDate = {
        OrderprocessingDate: selectOrder.OrderprocessDate.OrderprocessingDate,
        OrderDispatchedDate: selectOrder.OrderprocessDate.OrderDispatchedDate,
        OutfordeliveryDate: selectOrder.OrderprocessDate.OutfordeliveryDate,
        OrderDeliveredDate: selectOrder.OrderprocessDate.OrderDeliveredDate,
        OrderCancelledDate: date,
      };
    } else {
      var OrderprocessDate = {};
    }

    const formData = {
      Status: 0,
      StatusText: "Order Cancelled",
      orderid: selectOrder._id,
      remark: {
        OrderCancelRemark: "Your delivery is cancelled",
      },
      OrderprocessDate: OrderprocessDate,
    };
    const data = await dispatch(updateOrder(formData));

    const getClientForm = {
      Mobile: selectOrder.Mobile,
    };

    const getOrderClient = await dispatch(getClientByMob(getClientForm));

    let getclient = getOrderClient.payload.client;
    if (selectOrder.CurrentStatus === "Not Paid") {
      if (selectOrder.wallet > 0) {
        const formDta = {
          WalletBalance:
            Number(getclient.WalletBalance) + Number(selectOrder.wallet),
          claintid: selectOrder.ClientId,
        };
        dispatch(clientUpdate(formDta));

        const walletForm = {
          ClientId: getclient._id,
          previousAmount: getclient.WalletBalance,
          availableAmount:
            Number(getclient.WalletBalance) + Number(selectOrder.wallet),
          transactionAmount: Number(selectOrder.wallet),
          orderId: selectOrder._id,
          Type: "Wallet Credit on Order Cancel",
          ClientName: getclient.Name,
          Mobile: getclient.Mobile,
        };
        await dispatch(walletTransitionPost(walletForm));
      }
    } else if (selectOrder.CurrentStatus === "Paid") {
      const formDta = {
        WalletBalance:
          Number(getclient.WalletBalance) + Number(selectOrder.TotalAmount),
        claintid: selectOrder.ClientId,
      };
      dispatch(clientUpdate(formDta));
      const walletForm = {
        ClientId: getclient._id,
        previousAmount: getclient.WalletBalance,
        availableAmount:
          Number(getclient.WalletBalance) + Number(selectOrder.wallet),
        transactionAmount: Number(selectOrder.wallet),
        orderId: selectOrder._id,
        Type: "Wallet Credit on Order Cancel",
        ClientName: getclient.Name,
        Mobile: getclient.Mobile,
      };
      await dispatch(walletTransitionPost(walletForm));
    } else if (selectOrder.CurrentStatus === "Partial Payment") {
      const formDta = {
        WalletBalance:
          Number(getclient.WalletBalance) +
          Number(selectOrder.TotalAmount) -
          Number(selectOrder.GrandTotal),
        claintid: selectOrder.ClientId,
      };
      dispatch(clientUpdate(formDta));
      const walletForm = {
        ClientId: getclient._id,
        previousAmount: getclient.WalletBalance,
        availableAmount:
          Number(getclient.WalletBalance) +
          Number(selectOrder.TotalAmount) -
          Number(selectOrder.GrandTotal),
        transactionAmount: Number(selectOrder.wallet),
        orderId: selectOrder._id,
        Type: "Wallet Credit on Partial Order Cancel",
        ClientName: getclient.Name,
        Mobile: getclient.Mobile,
      };
      await dispatch(walletTransitionPost(walletForm));

      dispatch(getOrderbyClId(clientid));
    }

    const sendOptthowwp = async () => {
      const name = selectOrder.ClientName;
      const orderId = String(selectOrder._id.slice(18).toUpperCase());
      const amount = String(selectOrder.GrandTotal);
      const mobile = selectOrder.Mobile;
      let data = JSON.stringify({
        countryCode: "+91",
        phoneNumber: mobile,
        callbackData: "some text here",
        type: "Template",
        template: {
          name: "order_cancelled",
          languageCode: "en",
          bodyValues: [name, orderId, amount],
        },
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.interakt.ai/v1/public/message/",
        headers: {
          Authorization:
            "Basic djlWdldPY1habHlBbEtoZkFFUl8zVWdQTDAycmJqWl9BWHd4dDF4eHVjMDo=",
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
        });
    };
    sendOptthowwp();
  };

  const orderPayNowClick = async (e, order) => {
    const orderCurrent = order;
    const generateNewTokenId = await dispatch(
      gerenatenewTokrnId(orderCurrent._id)
    );
    if (generateNewTokenId.payload.success) {
      const newtokenId = generateNewTokenId.payload.respo;
      var config = {
        root: "",
        style: {
          bodyBackgroundColor: "#fafafb",
          bodyColor: "",
          themeBackgroundColor: "#0FB8C9",
          themeColor: "#ffffff",
          headerBackgroundColor: "#284055",
          headerColor: "#ffffff",
          errorColor: "",
          successColor: "",
          card: {
            padding: "",
            backgroundColor: "",
          },
        },
        data: {
          orderId: orderCurrent._id,
          token: newtokenId.body.txnToken,
          tokenType: "TXN_TOKEN",
          amount: orderCurrent.GrandTotal /* update amount */,
        },
        payMode: {
          labels: {},
          filter: {
            exclude: [],
          },
          order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
        },
        website: "WEBSTAGING",
        flow: "DEFAULT",
        merchant: {
          mid: "SHANTI03800179145316",
          redirect: false,
        },
        handler: {
          transactionStatus: function transactionStatus(paymentStatus) {
            // setPaymentStatus(paymentStatus);
            window.Paytm.CheckoutJS.close();
            if (paymentStatus.STATUS === "TXN_SUCCESS") {
              const orderPaymentForm = {
                orderid: orderCurrent._id,
                PaymentStatus: paymentStatus.STATUS,
                CurrentStatus: "Paid",
                TxnId: paymentStatus.TXNID,
                PaymentMode: "PayTM",
                GrandTotal: 0,
              };
              dispatch(updateOrder(orderPaymentForm));
              toast.success("PAYMENT SUCCESSFULL", {
                position: "top-center",
                autoClose: 500,
              });
              // navigate("/payment-success");
            } else if (paymentStatus.STATUS === "TXN_FAILURE") {
              const orderPaymentForm = {
                orderid: orderCurrent._id,
                PaymentStatus: paymentStatus.STATUS,
                CurrentStatus: "Failed",
                TxnId: "NA",
              };

              dispatch(updateOrder(orderPaymentForm));
              toast.error("PAYMENT FAILED", {
                position: "top-center",
                autoClose: 500,
              });
              // navigate("/payment-failed");
            } else if (paymentStatus.STATUS === "PENDING") {
              toast.warn("PAYMENT PENDING", {
                position: "top-center",
                autoClose: 500,
              });
              const orderPaymentForm = {
                orderid: orderCurrent._id,
                PaymentStatus: paymentStatus.STATUS,
                CurrentStatus: "Pending",
                TxnId: paymentStatus.TXNID,
                PaymentMode: "PayTM",
              };

              dispatch(updateOrder(orderPaymentForm));
              // navigate("/order-success");
            }
          },
          notifyMerchant: function notifyMerchant(eventName, data) {
            // const orderPaymentForm = {
            //   orderid: orderCurrent._id,
            //   PaymentStatus: "PAYMENT FAILED",
            //   CurrentStatus: "Failed",
            // };
            // dispatch(updateOrder(orderPaymentForm));
            // toast.error("PAYMENT FAILED", {
            //   position: "top-center",
            //   autoClose: 500,
            // });
            // navigate("/payment-failed");
          },
        },
      };
      if (window.Paytm && window.Paytm.CheckoutJS) {
        window.Paytm.CheckoutJS.init(config)
          .then(function onSuccess() {
            window.Paytm.CheckoutJS.invoke();
          })
          .catch(function onError(error) {
            console.log("error => ", error);
          });
      }
    }
  };

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="pills-dashboard"
        role="tabpanel"
        aria-labelledby="pills-dashboard-tab"
      >
        <div className="dashboard-home">
          <Modal size="md" isOpen={model} toggle={() => setModel(!model)}>
            <ModalHeader>
              <EditProfile />
            </ModalHeader>
          </Modal>
          <div className="d-flex justify-content-between">
            <div className="title pt-2">
              <h2>
                My Wallet :{" "}
                <span
                  style={{
                    background: "#0da487",
                    color: "#fff",
                    borderRadius: "10px",
                  }}
                  className=" p-2"
                >
                  {loginData.WalletBalance}
                </span>
              </h2>
              {/* <span className="title-leaf">
                <svg className="icon-width bg-gray">
                  <use xlinkHref="https://themes.pixelstrap.com/fastkart/assets/svg/leaf.svg#leaf" />
                </svg>
              </span> */}
            </div>
          </div>
          {/* <div className="dashboard-user-name">
            <h6 className="text-content">
              Hello, <b className="text-title">Mr./Ms. {loginData.Name}</b>
            </h6>
          </div> */}

          <div className="profile-tab dashboard-bg-box">
            <div className="dashboard-title dashboard-flex">
              <h3>Profile Name</h3>
              {/* <button
                className="btn btn-sm theme-bg-color text-white"
                data-bs-toggle="modal"
                data-bs-target="#edit-profile"
              >
                Edit Profile
              </button> */}
            </div>
            <ul>
              <li className="d-flex">
                <h5>Name :</h5>
                <h5>{loginData.Name}</h5>
              </li>
              <li className="d-flex">
                <h5>Email Address :</h5>
                <h5>{loginData.Email}</h5>
              </li>
              <li className="d-flex">
                <h5>Country / Region :</h5>
                <h5>India</h5>
              </li>
              <li className="d-flex">
                <h5>Year Registared :</h5>
                <h5>{moment(loginData.createdAt).format("YYYY")}</h5>
              </li>
              {/* <li>
                <h5>Category :</h5>
                <h5>Grocery</h5>
              </li>
              <li>
                <h5>Street Address :</h5>
                <h5>234 High St</h5>
              </li>
              <li>
                <h5>City / State :</h5>
                <h5>107 Veltri Drive</h5>
              </li>
              <li>
                <h5>Zip :</h5>
                <h5>B23 6SN</h5>
              </li> */}
            </ul>
          </div>
        </div>

        <section className="product-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 wow fadeInUp">
                <div className="row g-4">
                  <div className="col-12">
                    <div className="product-section-box">
                      <h2>ORDERS</h2>

                      <div className="tab-content custom-tab" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="description"
                          role="tabpanel"
                          aria-labelledby="description-tab"
                        >
                          <div className="dashboard-order">
                            {clientOrder.length > 0 ? (
                              <>
                                <div className="order-contain">
                                  {clientOrder
                                    // .slice()
                                    // .reverse()
                                    .map((order, index) => (
                                      <div
                                        className="order-box dashboard-bg-box"
                                        key={index}
                                      >
                                        <div className="order-container">
                                          <Link
                                            to={`/ordertracking/${order._id}`}
                                          >
                                            <div className="order-icon">
                                              {/* <i data-feather="box" /> */}
                                              <img
                                                src="../assets/images/cart.png"
                                                alt="img"
                                                width={100}
                                              />
                                            </div>
                                          </Link>
                                          <Link
                                            to={`/ordertracking/${order._id}`}
                                          >
                                            <div className="order-detail">
                                              <h4>
                                                <h5 className="success-bg me-2  text-success">
                                                  Net Payable:
                                                </h5>
                                                <h5 style={{ color: "red" }}>
                                                  ₹{order.GrandTotal}
                                                </h5>
                                              </h4>
                                              <h4>
                                                <h5 className="success-bg me-2  text-success">
                                                  Order Status:
                                                </h5>
                                                <h5 style={{ color: "red" }}>
                                                  {order.StatusText}
                                                </h5>
                                              </h4>

                                              <h4>
                                                <h5 className="success-bg me-2  text-success">
                                                  Payment Type:
                                                </h5>
                                                <h5 style={{ color: "red" }}>
                                                  {order.PaymentMode}
                                                </h5>
                                              </h4>

                                              <h4>
                                                <h5 className="success-bg me-2  text-success">
                                                  Payment Status:
                                                </h5>
                                                <h5 style={{ color: "red" }}>
                                                  {order.CurrentStatus}
                                                </h5>
                                              </h4>

                                              {order.CurrentStatus ===
                                              "Paid" ? (
                                                <> </>
                                              ) : (
                                                <></>
                                              )}

                                              <h4>
                                                <h5 className="success-bg me-2  text-success">
                                                  Transaction Status:
                                                </h5>
                                                <h5 style={{ color: "red" }}>
                                                  {order.PaymentStatus}
                                                </h5>
                                              </h4>

                                              <h4>
                                                <h5 className="success-bg me-2 text-success">
                                                  Order Date:
                                                </h5>
                                                <h5 style={{ color: "red" }}>
                                                  {moment(
                                                    order.createdAt
                                                  ).format("MMM DD YY")}
                                                </h5>
                                              </h4>
                                              <h4>
                                                <h5 className="success-bg me-2 text-success">
                                                  Expected Delivery Date:
                                                </h5>
                                                <h5 style={{ color: "red" }}>
                                                  {moment(
                                                    order.ExpectedDelDate
                                                  ).format("MMM DD YY")}
                                                </h5>
                                              </h4>
                                            </div>
                                          </Link>
                                          <div className="d-flex d-flex flex-column">
                                            <div className="addtocart_btn my-1">
                                              <Link
                                                to={`/ordertracking/${order._id}`}
                                              >
                                                <button
                                                  className="btn buy-button"
                                                  style={{
                                                    background: "#0e947a",
                                                    color: "#fff",
                                                  }}
                                                >
                                                  View order
                                                </button>
                                              </Link>
                                            </div>
                                            {order.Status === 1 ? (
                                              <>
                                                <div className="addtocart_btn my-1">
                                                  <button
                                                    className="btn buy-button"
                                                    style={{
                                                      background: "#0e947a",
                                                      color: "#fff",
                                                    }}
                                                    onClick={(e) =>
                                                      orderCancelClick(e, order)
                                                    }
                                                  >
                                                    Order Cancel
                                                  </button>
                                                </div>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            {/* {order.Status === 0 ||
                                            order.Status === 5 ? (
                                              <></>
                                            ) : (
                                              <>
                                                <div className="addtocart_btn my-1">
                                                  <button
                                                    className="btn buy-button"
                                                    style={{
                                                      background: "#0e947a",
                                                      color: "#fff",
                                                    }}
                                                    onClick={(e) =>
                                                      orderPayNowClick(e, order)
                                                    }
                                                  >
                                                    Pay Now
                                                  </button>
                                                </div>
                                              </>
                                            )} */}

                                            {/* <div className="addtocart_btn my-1">
                                              <button
                                                className="btn buy-button"
                                                style={{
                                                  background: "#0e947a",
                                                  color: "#fff",
                                                }}
                                              >
                                                Re - Order
                                              </button>
                                            </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="order-contain">
                                  <section className="section-404 section-lg-space">
                                    <div className="container-fluid-lg">
                                      <div className="row">
                                        <div className="col-12">
                                          <div className="image-404">
                                            <img
                                              src="../assets/images/inner-page/emptycart.png"
                                              className="img-fluid blur-up lazyload"
                                              alt
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="contain-404">
                                            <Link to="/">
                                              <button className="btn btn-md text-white theme-bg-color mt-4 mx-auto">
                                                Back To Home Screen
                                              </button>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default MyDashBoard;
