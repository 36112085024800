import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  newOrder,
  setCurrentOrder,
  setpaymentUpdateOrder,
  updateOrder,
} from "../../redux/order/OrderSlice";
import {
  clearCart,
  netpayableUpdate,
  setpaymentId,
  walletamountUpdate,
} from "../../redux/cart/CartSlice";
import {
  clientUpdate,
  paymentTypeChange,
  walletTransitionPost,
  walletUpdate,
  walletUpdateAfterOrder,
  walletUseUpdate,
} from "../../redux/athentication/Athentication";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const RightSideCheckOut = () => {
  const {
    CartItems,
    cartTotalAmount,
    ProductTotal,
    cartCouponDiscount,
    logisticsAmount,
    netPayable,
    cartTotalMrp,
    CouponDiscountAmount,
    cartTotalPrice,
    updateNetPayable,
    usedWalletAmount,
  } = useSelector((state) => state.Cart);
  const {
    name,
    currentAddress,
    clientid,
    email,
    loginData,
    walletUse,
    paymentType,
  } = useSelector((state) => state.Athentication);

  const [mainerrormassage, setMainErrormassage] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [cartamountDetails, setCartamountDetails] = useState("");

  const [btnLoading, setbtnLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let cartTotalAmountN = 0;
    let totalAmount = 0;
    let netPayableN = 0;
    let cartTotalMrpN = 0;
    let cartTotalPriceN = 0;
    let logisticsAmountN = 40;
    const totalitem = CartItems;

    for (let i = 0; i < totalitem.length; i++) {
      cartTotalAmountN =
        Number(cartTotalAmountN) + Number(CartItems[i].TotalAmount);
      cartTotalMrpN = Number(cartTotalMrpN) + Number(CartItems[i].TotalMrp);
      cartTotalPriceN =
        Number(cartTotalPriceN) + Number(CartItems[i].TotalPrice);
    }

    if (cartTotalAmountN >= 500) {
      logisticsAmountN = 0;
    } else {
      logisticsAmountN = 40;
    }
    totalAmount =
      Number(cartTotalAmountN) +
      Number(logisticsAmountN) -
      Number(CouponDiscountAmount);
    netPayableN = Number(totalAmount) - Number(usedWalletAmount);

    const cartAmountsForm = {
      cartTotalAmount: Number(cartTotalAmountN),
      netPayable: Number(netPayableN),
      cartTotalMrp: Number(cartTotalMrpN),
      cartTotalPrice: Number(cartTotalPriceN),
      logisticsAmount: Number(logisticsAmountN),
      totalAmount: Number(totalAmount),
    };
    setCartamountDetails(cartAmountsForm);
  }, [CartItems]);

  useEffect(() => {
    if (CartItems.length === 0) {
      navigate("/");
    }
  }, []);

  const cashOnDeliveryHandleSubmit = async (e) => {
    if (!btnLoading) {
      setbtnLoading(true);
      const addressString = `${
        currentAddress.HNo +
        "," +
        currentAddress.StreetDet +
        "," +
        currentAddress.AName +
        "," +
        currentAddress.LandMark +
        "," +
        currentAddress.City +
        "," +
        currentAddress.State +
        "," +
        currentAddress.Type +
        "," +
        currentAddress.Pincode
      }`;

      let grandasTotal = 0;
      if (!walletUse) {
        grandasTotal = cartamountDetails.netPayable;
      } else {
        grandasTotal = updateNetPayable;
      }
      var date = new Date();
      date.setDate(date.getDate() + 1);
      if (currentAddress !== "" && cartamountDetails.totalAmount > 0) {
        const orderData = {
          ClientId: clientid,
          ClientName: name,
          TotalAmount: Number(cartamountDetails.totalAmount),
          Email: email,
          DeliveryCharge: logisticsAmount,
          wallet: usedWalletAmount,
          GrandTotal:
            Number(cartamountDetails.totalAmount) - Number(usedWalletAmount),
          Address: addressString,
          AreaName: currentAddress.StreetDet,
          Mobile: loginData.Mobile,
          PaymentStatus: "COD",
          PaymentMode: "COD",
          ExpectedDelDate: date,
          ProductCount: CartItems.length,
          Saving:
            cartamountDetails.cartTotalMrp +
            CouponDiscountAmount -
            cartamountDetails.cartTotalPrice,
          Cashback: "0",
          CurrentStatus: "Not Paid",
          TxnId: "NA",
          couponDetails: {
            // coupon: cartCoupon.CouponName,
            // couponCode: cartCoupon.Title,
            // couponDis: CouponDiscountAmount,
            // CouponTitle: cartCoupon.Title,
            // CouponDescription: cartCoupon.Description,
          },
          OrderProducts: CartItems,
          currentAddress: currentAddress,
        };
        const order = await dispatch(newOrder(orderData));

        if (!order) {
        } else {
          if (order.payload.success) {
            dispatch(clearCart());
            const orderId = order.payload.order;
            const userUpdateForm = {
              claintid: loginData._id,
              WalletBalance: loginData.WalletBalance - usedWalletAmount,
            };

            const client = await dispatch(clientUpdate(userUpdateForm));
            dispatch(
              walletUpdateAfterOrder(loginData.WalletBalance - usedWalletAmount)
            );

            const walletForm = {
              ClientId: loginData._id,
              previousAmount: loginData.WalletBalance,
              availableAmount: loginData.WalletBalance - usedWalletAmount,
              transactionAmount: usedWalletAmount * -1,
              orderId: orderId._id,
              Type: "Wallet debit on New Order",
              ClientName: loginData.Name,
              Mobile: loginData.Mobile,
            };

            dispatch(walletTransitionPost(walletForm));

            const sendOptthowwp = async () => {
              // const name = name;
              const mobile = loginData.Mobile;
              let data = JSON.stringify({
                countryCode: "+91",
                phoneNumber: mobile,
                callbackData: "some text here",
                type: "Template",
                template: {
                  name: "order_placed_prepaid",
                  languageCode: "en",
                  bodyValues: [name],
                },
              });

              let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://api.interakt.ai/v1/public/message/",
                headers: {
                  Authorization:
                    "Basic djlWdldPY1habHlBbEtoZkFFUl8zVWdQTDAycmJqWl9BWHd4dDF4eHVjMDo=",
                  "Content-Type": "application/json",
                },
                data: data,
              };

              axios
                .request(config)
                .then((response) => {
                })
                .catch((error) => {
                  console.log(error);
                });
            };
            sendOptthowwp();

            toast.success("ORDER PLACED SUCCESSFULL", {
              position: "top-center",
              autoClose: 500,
            });
            navigate("/order-success");
          } else {
            toast.error("ORDER NOT PLACED SUCCESSFULL", {
              position: "top-center",
              autoClose: 2000,
            });
            setbtnLoading(false);
          }
        }
      } else if (cartamountDetails.totalAmount === 0) {
        toast.error("Please Check The Cart Products", {
          position: "top-center",
          autoClose: 2000,
        });
      } else {
        toast.error("Please Add Address", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    }
  };

  const payTmPayHandleSubmit = async (e) => {
    if (!btnLoading) {
      setbtnLoading(true);
      const addressString = `${
        currentAddress.HNo +
        "," +
        currentAddress.StreetDet +
        "," +
        currentAddress.AName +
        "," +
        currentAddress.LandMark +
        "," +
        currentAddress.City +
        "," +
        currentAddress.State +
        "," +
        currentAddress.Type +
        "," +
        currentAddress.Pincode
      }`;

      let grandasTotal = 0;
      if (!walletUse) {
        grandasTotal = cartamountDetails.netPayable;
      } else {
        grandasTotal = updateNetPayable;
      }
      var date = new Date();
      date.setDate(date.getDate() + 1);
      if (currentAddress !== "") {
        const orderData = {
          ClientId: clientid,
          ClientName: name,
          TotalAmount: Number(cartamountDetails.totalAmount),
          Email: email,
          DeliveryCharge: cartamountDetails.logisticsAmount,
          wallet: usedWalletAmount,
          GrandTotal:
            Number(cartamountDetails.totalAmount) - Number(usedWalletAmount),
          Address: addressString,
          AreaName: currentAddress.StreetDet,
          Mobile: loginData.Mobile,
          PaymentStatus: "COD",
          PaymentMode: "COD",
          TxnId: "NA",
          CurrentStatus: "Not Paid",
          ExpectedDelDate: date,
          ProductCount: CartItems.length,
          Saving:
            cartamountDetails.cartTotalMrp +
            CouponDiscountAmount -
            cartamountDetails.cartTotalPrice,
          Cashback: "0",

          couponDetails: {
            // coupon: cartCoupon.CouponName,
            // couponCode: cartCoupon.Title,
            // couponDis: CouponDiscountAmount,
            // CouponTitle: cartCoupon.Title,
            // CouponDescription: cartCoupon.Description,
          },
          OrderProducts: CartItems,
        };
        const order = await dispatch(newOrder(orderData));

        if (!order) {
        } else {
          if (order.payload.success) {
            const orderProduct = order.payload.order;
            dispatch(setCurrentOrder(orderProduct));
            const orderId = orderProduct._id.slice(18).toUpperCase();
            const amount = orderProduct.GrandTotal;
            const groceryPro = orderProduct.OrderProducts;

            var options = {
              key: "rzp_live_9MPhkti7Rz5gAI",
              // amount: 1 * 100,
              amount: amount * 100,
              currency: "INR",
              description: groceryPro[0].ProductName,
              image:
                "https://res.cloudinary.com/dh1fsseho/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency/v1669977353/Avatar/avatar2_z6yynb.jpg",
              name: orderProduct.ClientName,
              prefill: {
                email: loginData.Email,
                contact: orderProduct.Mobile,
                name: orderProduct.ClientName,
              },
              theme: { color: "#F37254" },

              handler: function (response) {
                if (response.razorpay_payment_id) {
                  const paymentId = response.razorpay_payment_id;
                  dispatch(setpaymentId(paymentId));

                  const updateOrder = async () => {
                    const config = {
                      Headers: { "Content-Type": "application/json" },
                    };
                    const formdata = {
                      orderId: orderProduct._id,
                      GrandTotal: 0,
                      PaymentStatus: "Success",
                      PaymentMode: "razorpay",
                      CurrentStatus: "Paid",
                      TxnId: paymentId,
                    };
                    const url = `${Baseurl}/api/v1/order/${formdata.orderId}`;
                    const resp = await axios.put(url, formdata, config);

                    if (resp.data.success) {
                      const orderProductu = resp.data.order;
                      dispatch(setCurrentOrder(orderProductu));
                      navigate("/payment-success");
                      dispatch(setpaymentId(""));
                    }
                  };
                  updateOrder();
                }
              },
              modal: {
                ondismiss: function () {
                  const updateOrder = async () => {
                    const config = {
                      Headers: { "Content-Type": "application/json" },
                    };
                    const formdata = {
                      orderId: orderProduct._id,
                      PaymentStatus: "Failed",
                      PaymentMode: "razorpay",
                      CurrentStatus: "Not-Paid",
                    };
                    const url = `${Baseurl}/api/v1/order/${formdata.orderId}`;
                    const resp = await axios.put(url, formdata, config);

                    if (resp.data.success) {
                      const orderProductu = resp.data.order;
                      dispatch(setCurrentOrder(orderProductu));
                      navigate("/payment-failed");
                    }
                  };
                  updateOrder();
                },
              },
            };
            const rzp1 = new window.Razorpay(options);

            rzp1.open();

            toast.success("ORDER PLACED SUCCESSFULL", {
              position: "top-center",
              autoClose: 500,
            });

            setTimeout(() => {
              dispatch(clearCart());
            }, 3000);
          } else {
            toast.error("ORDER FAILURE", {
              position: "top-center",
              autoClose: 2000,
            });
            setbtnLoading(false);
          }
        }
      } else {
        toast.error("Please Add Address", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    }
  };

  return (
    <>
      <div className="col-lg-4 p-2">
        <div className="right-side-summery-box">
          <div className="summery-box-2">
            <div className="summery-header">
              <h3>Order Summery</h3>
            </div>
            <ul className="summery-contain">
              {CartItems.map((cart, index) => (
                <li
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <img
                    src={cart.ImgUrl}
                    className="img-fluid blur-up lazyloaded checkout-image"
                    alt
                  />
                  <div style={{ width: "90%" }}>
                    <h4>{cart.ItemName} </h4>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div>
                        {Number(cart.Qty)} X ₹{Number(cart.Price)}
                      </div>
                      <div>
                        <h4> ₹{Number(cart.TotalAmount)}</h4>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <ul className="summery-total">
              <li>
                <h4>Total MRP</h4>
                <h4 className="price">
                  {" "}
                  ₹{Number(cartamountDetails.cartTotalMrp)}
                </h4>
              </li>
              <li>
                <h4>Sub-Total</h4>
                <h4 className="price"> ₹{cartamountDetails.cartTotalAmount}</h4>
              </li>
              <li>
                <h4>Shipping</h4>
                <h4 className="price"> ₹{cartamountDetails.logisticsAmount}</h4>
              </li>
              {/* <li>
                <h4>Tax</h4>
                <h4 className="price"> ₹0</h4>
              </li> */}

              <li>
                <h4>Coupon/Code</h4>
                <h4 className="price">- ₹{Number(cartCouponDiscount)}</h4>
              </li>
              <li>
                <h4>Wallet Used</h4>
                <h4 className="price"> - ₹{Number(usedWalletAmount)}</h4>
              </li>

              <li className="list-total">
                <h4>Grand Total (INR)</h4>
                <h4 className="price">
                  {" "}
                  {Number(cartamountDetails.totalAmount) -
                    Number(usedWalletAmount)}
                </h4>
              </li>
            </ul>
          </div>
          {/* <div className="checkout-offer">
            <div className="offer-title">
              <div className="offer-icon">
                <img
                  src="https://themes.pixelstrap.com/fastkart/assets/images/inner-page/offer.svg"
                  className="img-fluid"
                  alt
                />
              </div>
              <div className="offer-name">
                <h6>Available Offers</h6>
              </div>
            </div>
            <ul className="offer-detail">
              <li>
                <p>
                  Combo: BB Royal Almond/Badam Californian, Extra Bold 100 gm...
                </p>
              </li>
              <li>
                <p>
                  combo: Royal Cashew Californian, Extra Bold 100 gm + BB Royal
                  Honey 500 gm
                </p>
              </li>
            </ul>
          </div> */}

          {btnLoading === true ? (
            <>
              <button
                className="btn text-white btn-md w-100 mt-4 fw-bold"
                style={{ background: "#b3b3b3" }}
              >
                Place Order
              </button>

              <div className="d-flex justify-content-center">
                <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
                  Order is processing
                </h4>
                <BeatLoader
                  color={"#36d7b7"}
                  loading={btnLoading}
                  size={13}
                  className="mt-2 mx-2"
                />
              </div>
            </>
          ) : (
            <>
              <button
                className="btn theme-bg-color text-white btn-md w-100 mt-4 fw-bold"
                onClick={
                  paymentType === "CashOnDelivery"
                    ? cashOnDeliveryHandleSubmit
                    : payTmPayHandleSubmit
                }
              >
                Place Order
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RightSideCheckOut;
