import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IoIosArrowForward } from "react-icons/io";
import { BiSolidOffer } from "react-icons/bi";
import { FiMinus } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { LiaRupeeSignSolid } from "react-icons/lia";
import {
  addtoCart,
  decrementCart,
  getCartTotal,
  removefromCart,
} from "../../redux/cart/CartSlice";
import { MdDeleteOutline } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import CartSlider from "./CartSlider";

const CartProducts = () => {
  const { CartItems, cartTotalAmount, ProductTotal } = useSelector(
    (state) => state.Cart
  );

  const {
    address,
    currentAddress,
    addressmodel,
    loginData,
    clientWalletAmount,
  } = useSelector((state) => state.Athentication);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCartTotal());
  }, [CartItems, dispatch]);

  const removefromcart = (cartItem) => {
    dispatch(removefromCart(cartItem));
    dispatch(getCartTotal());
  };

  const handledecreasequtcart = (cartItem) => {
    dispatch(decrementCart(cartItem));
    dispatch(getCartTotal());
  };

  const handleincreasequtcart = (cartItem) => {
    dispatch(addtoCart(cartItem));
    dispatch(getCartTotal());
  };
  return (
    <>
      <div className="col-xxl-9 laptopversioncart">
        <div className="cart-table">
          <div className="table-responsive-xl">
            <table className="table">
              <tbody>
                {CartItems &&
                  CartItems.map((cart, index) => (
                    <tr className="product-box-contain" key={index}>
                      <td className="product-detail">
                        <div className="product border-0">
                          <a className="product-image">
                            <img
                              src={cart.ImgUrl}
                              className="img-fluid blur-up lazyload"
                              alt
                            />
                          </a>
                          <div className="product-detail">
                            <ul>
                              <li className="name">
                                <a>{cart.ItemName}</a>
                              </li>
                              <li className="text-content">
                                <span className="text-title">Sold By:</span>{" "}
                                {cart.Brand}
                              </li>
                              <li className="text-content">
                                <span className="text-title">Quantity</span> -
                                {cart.Qty}
                              </li>
                              <li>
                                <h5 className="text-content d-inline-block">
                                  Price :
                                </h5>
                                <span>₹{cart.Price}</span>
                                <span className="text-content">
                                  ₹{cart.Mrp}
                                </span>
                              </li>
                              <li>
                                <h5 className="saving theme-color">
                                  Saving : ₹20.68
                                </h5>
                              </li>
                              <li className="quantity-price-box">
                                <div className="cart_qty">
                                  <div className="input-group">
                                    <button
                                      type="button"
                                      className="btn qty-left-minus"
                                      data-type="minus"
                                      data-field
                                    >
                                      <i
                                        className="fa fa-minus ms-0"
                                        aria-hidden="true"
                                      />
                                    </button>
                                    <input
                                      className="form-control input-number qty-input"
                                      type="text"
                                      name="quantity"
                                      defaultValue={0}
                                    />
                                    <button
                                      type="button"
                                      className="btn qty-right-plus"
                                      data-type="plus"
                                      data-field
                                    >
                                      <i
                                        className="fa fa-plus ms-0"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <h5>Total: ₹35.10</h5>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                      <td className="price">
                        <h4 className="table-title text-content">Price</h4>
                        <h5>
                          ₹{cart.Price}{" "}
                          <del className="text-content">₹{cart.Mrp}</del>
                        </h5>
                        <h6 className="theme-color">
                          You Save : ₹{cart.Mrp - cart.Price}
                        </h6>
                      </td>
                      <td className="quantity">
                        <h4 className="table-title text-content">Qty</h4>
                        <div className="quantity-price">
                          <div className="cart_qty">
                            <div className="input-group">
                              <button
                                type="button"
                                className="btn qty-left-minus"
                                data-type="minus"
                                data-field
                                onClick={() => {
                                  if (cart.Qty > 0) {
                                    handledecreasequtcart(cart);
                                  }
                                }}
                              >
                                <i
                                  className="fa fa-minus ms-0"
                                  aria-hidden="true"
                                />
                              </button>

                              <input
                                className="form-control input-number qty-input"
                                type="text"
                                name="quantity"
                                defaultValue={0}
                                value={cart.Qty}
                                contentEditable="false"
                              />
                              <button
                                type="button"
                                className="btn qty-right-plus"
                                data-type="plus"
                                data-field
                                onClick={() => {
                                  if (10 >= cart.Qty) {
                                    handleincreasequtcart(cart);
                                  }
                                }}
                              >
                                <i
                                  className="fa fa-plus ms-0"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="subtotal">
                        <h4 className="table-title text-content">Total</h4>
                        <h5>₹{cart.TotalAmount}</h5>
                      </td>
                      <td className="save-remove">
                        <h4 className="table-title text-content">Action</h4>
                        <a
                          className="remove close_button"
                          onClick={() => removefromcart(cart)}
                          style={{ cursor: "pointer" }}
                        >
                          Remove
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#fff",
          margin: "5px",
          padding: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <BiSolidOffer size={25} color="#0e947a" />
            <h5 style={{ paddingLeft: "6px", fontWeight: "600" }}>
              View Coupons & Offers
            </h5>
          </div>
          <IoIosArrowForward size={20} color="#0e947a" />
        </div>
      </div>
      {CartItems &&
        CartItems.map((cart, index) => (
          <div
            className="swipersubcontainer1"
            style={{ margin: "10px", width: "95%" }}
          >
            <div className="swipersubcontainer2">
              <div>
                <img src={cart.ImgUrl} alt="image" height="40px" width="40px" />
              </div>
              <div className="swipersubcontainer3">
                <div>
                  <h6 style={{ fontWeight: "600" }}>{cart.ItemName}</h6>
                  <span>{cart.PackSize}</span>
                </div>
              </div>
            </div>
            <div className="swipersubcontainer2">
              {/* <div className="sliderbtn">
                      <p style={{ color: "red", fontWeight: "600" }}>Unlock</p>
                    </div> */}
              <div
                style={{
                  display: "flex",
                  width: "63px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "30px",
                  backgroundColor: "#0e947a",
                  color: "#fff",
                  borderRadius: "5px",
                  padding: "2PX",
                }}
              >
                <FiMinus onClick={() => handledecreasequtcart(cart)} />
                <span>{cart.Qty}</span>
                <GoPlus onClick={() => handleincreasequtcart(cart)} />
              </div>
              <div style={{ width: "40px" }}>
                <div className="sliderlastbtn">
                  <LiaRupeeSignSolid />
                  <span style={{ fontWeight: "600" }}>{cart.Price}</span>
                </div>
                <div className="sliderlastbtn1">
                  <LiaRupeeSignSolid color="#808080" />
                  <span
                    style={{
                      textDecorationLine: "line-through",
                      color: "#808080",
                    }}
                  >
                    {cart.Mrp}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      <CartSlider />


      {/* {CartItems &&
        CartItems.map((cart, index) => (
          <div className="mobileCart">
           
            <div className="MiddleCart ">
              <div className="ImageContainer">
                <img src={cart.ImgUrl} className="imagecart" alt="" />
              </div>
              <div className="detailContainer">
                <div className="CartRight">
                  <div className="cart-itemName">
                    {cart.ProductName}
                  </div>
                  <div>
                    {cart.CatName}
                  </div>
                  <div style={{padding:'3px 0px'}}>
                    {" "}
                    <span className="priceAmount"> ₹{cart.Price} </span>{" "}
                    {
                      cart.Mrp !== cart.Price ?
                        <span className="DisAmount">
                          {" "}
                          <del>₹{cart.Mrp}</del>
                        </span> : <></>
                    }
                  </div>
                  <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                  <div className=" quantity">
                    <button
                      type="button"
                      className="button1"
                      data-type="minus"
                      data-field
                      onClick={() => handledecreasequtcart(cart)}
                    >
                      <i className="fa fa-minus" aria-hidden="true" />
                    </button>
                    <input
                      className="form-control middlepart"
                      type="text"
                      name="quantity"
                      defaultValue={0}
                      value={cart.Qty}
                      contentEditable="false"
                    />
                    <button
                      type="button"
                      className="button1"
                      data-type="plus"
                      data-field
                      onClick={() => handleincreasequtcart(cart)}
                    >
                      <i className="fa fa-plus ms-0" aria-hidden="true" />
                    </button>
                  </div>
                  <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <span
                      className="remove1"
                      onClick={() => removefromcart(cart)}
                      style={{ cursor: "pointer" }}
                    >
                      <RiDeleteBin5Line />
                    </span>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))} */}
    </>
  );
};

export default CartProducts;
