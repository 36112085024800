import React from "react";

const WhoCanUse = (props) => {
  return (
    <>
      <div className="menandwomen">
        <ul>
          {props.suitablefor.map((suit, index) => (
            <>
              <li key={index}>. {suit.steps_of_suitable}</li> <br />
            </>
          ))}
        </ul>
      </div>
    </>
  );
};

export default WhoCanUse;
