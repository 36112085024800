import React, { useEffect, useState } from "react";
import KeyIngredients from "./KeyIngredients";
import HowtoUse from "./HowtoUse";
import WhoCanUse from "./WhoCanUse";
import WhyMamaearth from "./WhyMamaearth";
import ProductDescription from "./ProductDescription";
import IngedientsList from "./IngedientsListTable";
import IngedientsListTable from "./IngedientsListTable";
import { useDispatch, useSelector } from "react-redux";
import { changeTabStatus } from "../../../redux/athentication/Athentication";

const Details = ({ ingredient, useProcedure, suitablefor, listIngredient }) => {
  const { tabStatus } = useSelector((store) => store.Athentication);
  const [keyIngedients, setKeyIngedients] = useState(true);
  const [howtouse, setHowtoUse] = useState(false);
  const [whoCanUse, setWhocanUse] = useState(false);
  const [whyMamaEarth, setWhyMamaEarth] = useState(false);
  const [ingredientList, setIngredientList] = useState(false);
  const dispatch = useDispatch();
  const keyIngedientsChange = () => {
    dispatch(changeTabStatus(1));
    setKeyIngedients(true);
    setHowtoUse(false);
    setIngredientList(false);
    setWhocanUse(false);
    setWhyMamaEarth(false);
  };
  const howtouseChange = () => {
    dispatch(changeTabStatus(2));
    setHowtoUse(!false);
    setKeyIngedients(false);
    setIngredientList(false);
    setWhocanUse(false);
    setWhyMamaEarth(false);
  };
  const ingredientListChange = () => {
    dispatch(changeTabStatus(4));
    setIngredientList(true);
    setHowtoUse(false);
    setKeyIngedients(false);
    setWhocanUse(false);
    setWhyMamaEarth(false);
  };
  const whoCanUseChange = () => {
    dispatch(changeTabStatus(3));
    setWhocanUse(true);
    setWhyMamaEarth(false);
    setIngredientList(false);
    setHowtoUse(false);
    setKeyIngedients(false);
  };
  const whyMamaEarthChange = () => {
    setWhyMamaEarth(true);
    setIngredientList(false);
    setHowtoUse(false);
    setKeyIngedients(false);
    setWhocanUse(false);
  };
  useEffect(() => { }, []);
  return (
    <div className="detailsContainer" >
      <div className="detailsKeywords mt-3">
        <div className="innerdetails">
          {ingredient.length > 0 ? (
            <>
              <div
                onClick={keyIngedientsChange}
                style={{
                  backgroundColor: `${tabStatus === 1 ? "green" : "#fff"}`,
                  color: `${tabStatus === 1 ? "#fff" : "#555"}`,
                }}
              >
                Key Ingredients
              </div>
            </>
          ) : (
            <></>
          )}
          {useProcedure.length > 0 ? (
            <>
              <div
                onClick={howtouseChange}
                style={{
                  backgroundColor: `${tabStatus === 2 ? "green" : "#fff"}`,
                  color: `${tabStatus === 2 ? "#fff" : "#555"}`,
                }}
              >
                How to Use
              </div>
            </>
          ) : (
            <></>
          )}
          {suitablefor.length > 0 ? (
            <>
              <div
                onClick={whoCanUseChange}
                style={{
                  backgroundColor: `${tabStatus === 3 ? "green" : "#fff"}`,
                  color: `${tabStatus === 3 ? "#fff" : "#555"}`,
                }}
              >
                Who can Use
              </div>
            </>
          ) : (
            <></>
          )}

          {/* <div onClick={whyMamaEarthChange}>Why Bekhup</div> */}
          {listIngredient.length > 0 ? (
            <>
              <div
                onClick={ingredientListChange}
                style={{
                  backgroundColor: `${tabStatus === 4 ? "green" : "#fff"}`,
                  color: `${tabStatus === 4 ? "#fff" : "#555"}`,
                }}
              >
                Ingredient List
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="contentDivision">
        {keyIngedients && <KeyIngredients ingredient={ingredient} />}
        {howtouse && <HowtoUse useProcedure={useProcedure} />}
        {whoCanUse && <WhoCanUse suitablefor={suitablefor} />}
        {/* {whyMamaEarth && <WhyMamaearth/> } */}
        {ingredientList && (
          <IngedientsListTable listIngredient={listIngredient} />
        )}
      </div>
    </div>
  );
};

export default Details;
