import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { LoginOtp, loginAuthentication } from '../redux/athentication/Athentication';

const SmsSend = () => {
    const params = useParams()

    const dispatch = useDispatch()

    const [mobile, setMobile] = useState("")
    useEffect(() => {
        let number = params.mob
        let otpWithHash = params.otpWithHash

        if (number.length === 10) {

   
            const formdata = {
                number: number,
                OTP: otpWithHash,
            };
            dispatch(loginAuthentication(formdata))
        }
    }, [params.mob, params.otpWithHash])

    return (
        <div>{params.mob},{params.otpWithHash}</div>
    )
}

export default SmsSend