// import React from 'react'

// const Cancel = () => {
//   return (
//     <div>Cancel</div>
//   )
// }

// export default Cancel

import React from "react";

const Cancel = () => {
    return (
        <>
            <section className="container return">
                <h3 className="">Cancellation Policy</h3>
                <hr></hr>
                <ul>
                    <li className="" style={{ textAlign: "justify" }}>
                        <b>1. </b> While there is no option to cancel the order as we process the orders within 24 hours post order confirmation, you can get in touch with customer support team for a cancellation request. We will cancel the order if the same is not shipped from our warehouse. In such a case we will refund any payments already made by you.
                    </li>
                </ul>
            </section>
            <div className="mt-lg-5"></div>
        </>
    );
};

export default Cancel;

