import React, { useState } from "react";

const ProductDescription = ({ description }) => {
  let [state, setState] = useState(true);
  return (
    <>
      <div className="product_description">
        <h2>Product Description</h2>

        {state ? (
          <div className="product_description_inner1">
            <p>
              {description}...
              <span
                style={{ color: "rgb(0, 175, 239" }}
                onClick={() => {
                  setState(false);
                }}
              >
                Read More
              </span>
            </p>
          </div>
        ) : (
          ""
        )}
        {state ? (
          ""
        ) : (
          <div className="product_description_inner2">
            {description}
            <span
              style={{ color: "rgb(0, 175, 239" }}
              onClick={() => {
                setState(true);
              }}
            >
              Read Less
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductDescription;
