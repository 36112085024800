import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiSolidTimeFive } from "react-icons/bi";
import { FaShoppingCart, FaWallet } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { Modal, ModalHeader } from "reactstrap";
import moment from "moment/moment";
import {
  addressModelStatus,
  currAdress,
  paymentTypeChange,
  walletUpdate,
  walletUseUpdate,
} from "../../redux/athentication/Athentication";
import AddAddress from "../../component/account/dashboard/address/AddAddress";
import {
  netpayableUpdate,
  walletamountUpdate,
} from "../../redux/cart/CartSlice";

import {
  EffectFade,
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const LeftSideCheckout = () => {
  const {
    address,
    currentAddress,
    addressmodel,
    loginData,
    clientWalletAmount,
    walletUse,
    paymentType,
  } = useSelector((state) => state.Athentication);

  const { netPayable, usedWalletAmount, CartItems } = useSelector(
    (state) => state.Cart
  );

  const dispatch = useDispatch();

  const [selectaddress, setSelectAddress] = useState("");
  const [expectDeliveryTime, setExpectDeliveryTime] = useState("");
  const [walletstatus, setWalletStatus] = useState(Boolean(0));

  const [cartamountDetails, setCartamountDetails] = useState("");

  useEffect(() => {
    let cartTotalAmountN = 0;
    let netPayableN = 0;
    let cartTotalMrpN = 0;
    let cartTotalPriceN = 0;
    let logisticsAmountN = 40;
    const totalitem = CartItems;

    for (let i = 0; i < totalitem.length; i++) {
      cartTotalAmountN =
        Number(cartTotalAmountN) + Number(CartItems[i].TotalAmount);
      cartTotalMrpN = Number(cartTotalMrpN) + Number(CartItems[i].TotalMrp);
      cartTotalPriceN =
        Number(cartTotalPriceN) + Number(CartItems[i].TotalPrice);
    }

    if (cartTotalAmountN >= 500) {
      logisticsAmountN = 0;
    } else {
      logisticsAmountN = 40;
    }
    netPayableN = Number(cartTotalAmountN) + Number(logisticsAmountN);

    const cartAmountsForm = {
      cartTotalAmount: Number(cartTotalAmountN),
      netPayable: Number(netPayableN),
      cartTotalMrp: Number(cartTotalMrpN),
      cartTotalPrice: Number(cartTotalPriceN),
      logisticsAmount: Number(logisticsAmountN),
    };
    setCartamountDetails(cartAmountsForm);
  }, [CartItems]);

  useEffect(() => {
    var date = new Date();
    date.setDate(date.getDate() + 1);
    setExpectDeliveryTime(date);
  }, []);

  const selectclintddress = (evt, data) => {
    dispatch(currAdress(data));
    // const { value } = evt.target;
    // const index = evt.nativeEvent.target.selectedIndex;
    // setSelectAddress(value);
  };

  const statuschange = async (evt) => {
    const changedStatus = !walletstatus;
    const walletamount = loginData.WalletBalance;
    setWalletStatus(changedStatus);
    if (changedStatus === true) {
      if (cartamountDetails.netPayable > walletamount) {
        dispatch(netpayableUpdate(cartamountDetails.netPayable - walletamount));
        dispatch(walletamountUpdate(walletamount));
        dispatch(walletUseUpdate(true));
      } else {
        dispatch(netpayableUpdate(0));
        dispatch(walletamountUpdate(cartamountDetails.netPayable));
        dispatch(walletUseUpdate(true));
      }
    } else {
      dispatch(netpayableUpdate(cartamountDetails.netPayable));
      dispatch(walletamountUpdate(0));
      dispatch(walletUseUpdate(false));
    }
  };

  return (
    <>
      <div className="col-lg-8 p-2">
        <div className="left-sidebar-checkout">
          <div className="checkout-detail-box">
            <ul>
              <li>
                <div
                  className="checkout-icon"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MdLocationOn style={{ fontSize: "20px", color: "" }} />
                </div>
                <div className="checkout-box">
                  <div className="d-flex justify-content-between">
                    <div className="checkout-title">
                      <h4>Delivery Address</h4>
                    </div>
                    <div
                      className="checkout-title"
                      onClick={() => dispatch(addressModelStatus(true))}
                      style={{ cursor: "pointer" }}
                    >
                      <h4>Add Address</h4>
                    </div>
                  </div>

                  <div className="checkout-detail">
                    {address.length !== 0 && currentAddress !== "" ? (
                      <>
                        {" "}
                        <Swiper
                          grabCursor={true}
                          navigation={false}
                          pagination={true}
                          mousewheel={false}
                          keyboard={true}
                          draggable={true}
                          effect={"fade"}
                          modules={[
                            EffectFade,
                            Navigation,
                            Pagination,
                            Mousewheel,
                            Keyboard,
                            Autoplay,
                          ]}
                          loop={true}
                          autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                          }}
                          className="homeSlider mt-2"
                        >
                          <div className="row g-4">
                            {address.map((data, index) => (
                              <SwiperSlide key={index}>
                                <div
                                  className="col-xxl-6 col-lg-12 col-md-6 mt-2"
                                  key={index}
                                >
                                  <div className="delivery-address-box">
                                    <div>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="jack"
                                          id="flexRadioDefault1"
                                          checked={
                                            currentAddress._id === data._id
                                          }
                                          onChange={(e) => {
                                            selectclintddress(e, data);
                                          }}
                                          value={data._id}
                                          required
                                        />
                                      </div>
                                      <div className="label">
                                        <label>{data.Type}</label>
                                      </div>
                                      <ul className="delivery-address-detail">
                                        <li>
                                          {data.AName === "undefined" ? (
                                            <>
                                              <h4 className="fw-500">
                                                {loginData.Name}
                                              </h4>
                                            </>
                                          ) : (
                                            <>
                                              <h4 className="fw-500">
                                                {data.AName}
                                              </h4>
                                            </>
                                          )}
                                        </li>
                                        <li>
                                          <p className="text-content">
                                            <span className="text-title">
                                              Address :{" "}
                                            </span>
                                            {data.Address}
                                          </p>
                                        </li>
                                        <li>
                                          <h6 className="text-content">
                                            <span className="text-title">
                                              Pin Code :
                                            </span>{" "}
                                            {data.Pincode}
                                          </h6>
                                        </li>
                                        <li>
                                          <h6 className="text-content mb-0">
                                            <span className="text-title">
                                              Phone :
                                            </span>{" "}
                                            {data.Mobile}
                                          </h6>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                          </div>
                        </Swiper>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => dispatch(addressModelStatus(true))}
                        >
                          <h4
                            style={{
                              fontSize: "20px",
                              color: "red",
                              textTransform: "capitalize",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                          >
                            please add address
                          </h4>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </li>

              <li>
                <div
                  className="checkout-icon"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BiSolidTimeFive
                    // className="lord-icon  text-success"
                    style={{ fontSize: "20px", color: "" }}
                  />
                </div>
                <div className="checkout-box">
                  <div className="checkout-title">
                    <h4>Delivery Time</h4>
                  </div>
                  <div className="checkout-detail">
                    <div className="row g-4">
                      <div className="col-xxl-6">
                        <div className="delivery-option">
                          <div className="delivery-category">
                            <div className="shipment-detail">
                              <div className="form-check custom-form-check hide-check-box">
                                <label
                                  className="form-check-label"
                                  htmlFor="standard"
                                  style={{
                                    fontSize: "20px",
                                    color: "#0e947a",
                                    textTransform: "capitalize",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {moment(expectDeliveryTime).format(
                                    "DD MMMM YYYY"
                                  )}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="checkout-icon"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaWallet
                    // className="lord-icon  text-success"
                    style={{ fontSize: "20px", color: "" }}
                  />
                </div>
                <div className="checkout-box">
                  <div className="checkout-title">
                    <h4>
                      Wallet Amount :{" "}
                      <span
                        style={{
                          fontSize: "20px",
                          color: "#0e947a",
                          textTransform: "capitalize",
                          fontWeight: "bold",
                        }}
                      >
                        {loginData.WalletBalance - usedWalletAmount}
                      </span>{" "}
                    </h4>

                    <div class="checkbox-wrapper-6">
                      <div>
                        <input
                          className="tgl tgl-light"
                          id="cb1-6"
                          type="checkbox"
                          value="walletstatus"
                          checked={walletUse === true}
                          onChange={(e) => {
                            statuschange(e);
                          }}
                        />
                        <label className="tgl-btn" htmlFor="cb1-6"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="checkout-icon"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaShoppingCart
                    // className="lord-icon BiSolidTimeFive text-success"
                    style={{ fontSize: "20px", color: "" }}
                  />
                </div>
                <div className="checkout-box">
                  <div className="checkout-title">
                    <h4>Payment Option</h4>
                  </div>
                  <div className="checkout-detail">
                    <div
                      className="accordion accordion-flush custom-accordion"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <div
                          className="accordion-header"
                          id="flush-headingFour"
                        >
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour"
                          >
                            <div className="custom-form-check form-check mb-0">
                              <label
                                className="form-check-label"
                                htmlFor="cash"
                              >
                                <input
                                  className="form-check-input mt-0"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="cash"
                                  checked={paymentType === "CashOnDelivery"}
                                  onChange={(e) => {
                                    dispatch(
                                      paymentTypeChange("CashOnDelivery")
                                    );
                                  }}
                                />
                                Cash On Delivery
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <div className="accordion-header" id="flush-headingOne">
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                          >
                            <div className="custom-form-check form-check mb-0">
                              <label
                                className="form-check-label"
                                htmlFor="credit"
                              >
                                <input
                                  className="form-check-input mt-0"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="credit"
                                  checked={paymentType === "paytmPayment"}
                                  onChange={(e) => {
                                    dispatch(paymentTypeChange("paytmPayment"));
                                  }}
                                />
                                Pay Now
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="accordion-item">
                        <div className="accordion-header" id="flush-headingOne">
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                          >
                            <div className="custom-form-check form-check mb-0">
                              <label
                                className="form-check-label"
                                htmlFor="credit"
                              >
                                <input
                                  className="form-check-input mt-0"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="credit"
                                />
                                Credit or Debit Card
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          id="flush-collapseOne"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <div className="row g-2">
                              <div className="col-12">
                                <div className="payment-method">
                                  <div className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="credit2"
                                      placeholder="Enter Credit & Debit Card Number"
                                    />
                                    <label htmlFor="credit2">
                                      Enter Credit &amp; Debit Card Number
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4">
                                <div className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="expiry"
                                    placeholder="Enter Expiry Date"
                                  />
                                  <label htmlFor="expiry">Expiry Date</label>
                                </div>
                              </div>
                              <div className="col-xxl-4">
                                <div className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="cvv"
                                    placeholder="Enter CVV Number"
                                  />
                                  <label htmlFor="cvv">CVV Number</label>
                                </div>
                              </div>
                              <div className="col-xxl-4">
                                <div className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    placeholder="Enter Password"
                                  />
                                  <label htmlFor="password">Password</label>
                                </div>
                              </div>
                              <div className="button-group mt-0">
                                <ul>
                                  <li>
                                    <button className="btn btn-light shopping-button">
                                      Cancel
                                    </button>
                                  </li>
                                  <li>
                                    <button className="btn btn-animation">
                                      Use This Card
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <div className="accordion-header" id="flush-headingTwo">
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                          >
                            <div className="custom-form-check form-check mb-0">
                              <label
                                className="form-check-label"
                                htmlFor="banking"
                              >
                                <input
                                  className="form-check-input mt-0"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="banking"
                                />
                                Net Banking
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          id="flush-collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <h5 className="text-uppercase mb-4">
                              Select Your Bank
                            </h5>
                            <div className="row g-2">
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="bank1"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="bank1"
                                  >
                                    Industrial &amp; Commercial Bank
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="bank2"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="bank2"
                                  >
                                    Agricultural Bank
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="bank3"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="bank3"
                                  >
                                    Bank of America
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="bank4"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="bank4"
                                  >
                                    Construction Bank Corp.
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="bank5"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="bank5"
                                  >
                                    HSBC Holdings
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="bank6"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="bank6"
                                  >
                                    JPMorgan Chase &amp; Co.
                                  </label>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="select-option">
                                  <div className="form-floating theme-form-floating">
                                    <select
                                      className="form-select theme-form-select"
                                      aria-label="Default select example"
                                    >
                                      <option value="hsbc">
                                        HSBC Holdings
                                      </option>
                                      <option value="loyds">
                                        Lloyds Banking Group
                                      </option>
                                      <option value="natwest">
                                        Nat West Group
                                      </option>
                                      <option value="Barclays">Barclays</option>
                                      <option value="other">Others Bank</option>
                                    </select>
                                    <label>Select Other Bank</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <div
                          className="accordion-header"
                          id="flush-headingThree"
                        >
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                          >
                            <div className="custom-form-check form-check mb-0">
                              <label
                                className="form-check-label"
                                htmlFor="wallet"
                              >
                                <input
                                  className="form-check-input mt-0"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="wallet"
                                />
                                My Wallet
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          id="flush-collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <h5 className="text-uppercase mb-4">
                              Select Your Wallet
                            </h5>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <label
                                    className="form-check-label"
                                    htmlFor="amazon"
                                  >
                                    <input
                                      className="form-check-input mt-0"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="amazon"
                                    />
                                    Amazon Pay
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="gpay"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="gpay"
                                  >
                                    Google Pay
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="airtel"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="airtel"
                                  >
                                    Airtel Money
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="paytm"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="paytm"
                                  >
                                    Paytm Pay
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="jio"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="jio"
                                  >
                                    JIO Money
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="free"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="free"
                                  >
                                    Freecharge
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <Modal
          size="md"
          isOpen={addressmodel}
          toggle={() => dispatch(addressModelStatus(true))}
        >
          <ModalHeader>
            <AddAddress />
          </ModalHeader>
        </Modal>
      </div>
    </>
  );
};

export default LeftSideCheckout;
