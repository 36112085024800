import React, { useState } from "react";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("  ");
  const [message, setMessage] = useState("  ");

  const submitClick = () => {
    // const formdata = {
    //   name: name,
    //   lastName: lastName,
    //   email: email,
    //   mobile: mobile,
    //   message: message,
    // };
    setName("");
    setLastName("");
    setEmail("");
    setMobile("");
    setMessage("");
  };

  return (
    <>
      {/* Breadcrumb Section Start */}
      <section className="breadscrumb-section pt-0">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="breadscrumb-contain">
                <h2>Contact Us</h2>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="index.html">
                        <i className="fa-solid fa-house" />
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Contact Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Breadcrumb Section End */}

      {/* Contact Box Section Start */}
      <section className="contact-box-section">
        <div className="container-fluid-lg">
          <div className="row g-lg-5 g-3">
            <div className="col-lg-6">
              <div className="left-sidebar-box">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="contact-image">
                      <img
                        src="../assets/images/inner-page/contact-us.png"
                        className="img-fluid blur-up lazyloaded"
                        alt
                      />
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="contact-title">
                      <h3>Get In Touch</h3>
                    </div>
                    <div className="contact-detail">
                      <div className="row g-4">
                        <div className="col-xxl-6 col-lg-12 col-sm-6 p-2">
                          <div className="contact-detail-box">
                            <div className="contact-icon">
                              <i className="fa-solid fa-phone" />
                            </div>
                            <div className="contact-detail-title">
                              <h4>Phone</h4>
                            </div>
                            <div className="contact-detail-contain">
                              <p>(+91)99 89 75 9110</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-lg-12 col-sm-6 p-2">
                          <div className="contact-detail-box">
                            <div className="contact-icon">
                              <i className="fa-solid fa-phone" />
                            </div>
                            <div className="contact-detail-title">
                              <h4>Phone</h4>
                            </div>
                            <div className="contact-detail-contain">
                              <p>(+91)98 66 47 3814</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-lg-12 col-sm-6 p-2">
                          <div className="contact-detail-box">
                            <div className="contact-icon">
                              <i className="fa-solid fa-envelope" />
                            </div>
                            <div className="contact-detail-title">
                              <h4>Email</h4>
                            </div>
                            <div className="contact-detail-contain">
                              <p>
                                <a href="mailto: care@bekhup.com">
                                  care@bekhup.com
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-lg-12 col-sm-6 p-2">
                          <div className="contact-detail-box">
                            <div className="contact-icon">
                              <i className="fa-solid fa-location-dot" />
                            </div>
                            <div className="contact-detail-title ">
                              <h4
                                className="mt-1"
                                style={{ textTransform: "capitalize" }}
                              >
                                MOON SPAGYRIC PHARMA
                              </h4>
                            </div>
                            <div className="contact-detail-contain">
                              <div>
                                8-2-603/B/34/39/1,Zehra Nagar,Road No:10,Banjara
                                Hills,Hyderabad,Telangana State,Pincode: 500034.
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-xxl-6 col-lg-12 col-sm-6">
                          <div className="contact-detail-box">
                            <div className="contact-icon">
                              <i className="fa-solid fa-building" />
                            </div>
                            <div className="contact-detail-title">
                              <h4>Bournemouth Office</h4>
                            </div>
                            <div className="contact-detail-contain">
                              <p>Visitación de la Encina 22</p>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="title d-xxl-none d-block">
                <h2>Contact Us</h2>
              </div>
              <div className="right-sidebar-box">
                <div className="row">
                  <div className="col-xxl-6 col-lg-12 col-sm-6">
                    <div className="mb-md-4 mb-3 custom-form">
                      <label
                        htmlFor="exampleFormControlInput"
                        className="form-label"
                      >
                        First Name
                      </label>
                      <div className="custom-input">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput"
                          placeholder="Enter First Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <i className="fa-solid fa-user" />
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-lg-12 col-sm-6">
                    <div className="mb-md-4 mb-3 custom-form">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Last Name
                      </label>
                      <div className="custom-input">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                        <i className="fa-solid fa-user" />
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-lg-12 col-sm-6">
                    <div className="mb-md-4 mb-3 custom-form">
                      <label
                        htmlFor="exampleFormControlInput2"
                        className="form-label"
                      >
                        Email Address
                      </label>
                      <div className="custom-input">
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput2"
                          placeholder="Enter Email Address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <i className="fa-solid fa-envelope" />
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-lg-12 col-sm-6">
                    <div className="mb-md-4 mb-3 custom-form">
                      <label
                        htmlFor="exampleFormControlInput3"
                        className="form-label"
                      >
                        Phone Number
                      </label>
                      <div className="custom-input">
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleFormControlInput3"
                          placeholder="Enter Your Phone Number"
                          minLength={10}
                          maxLength={10}
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                        />
                        <i className="fa-solid fa-mobile-screen-button" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-md-4 mb-3 custom-form">
                      <label
                        htmlFor="exampleFormControlTextarea"
                        className="form-label"
                      >
                        Message
                      </label>
                      <div className="custom-textarea">
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea"
                          placeholder="Enter Your Message"
                          rows={6}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                        <i className="fa-solid fa-message" />
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-animation btn-md fw-bold ms-auto"
                  onClick={() => submitClick()}
                >
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Box Section End */}
    </>
  );
};

export default ContactUs;
